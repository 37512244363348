import {HttpContextToken, HttpErrorResponse, HttpHandlerFn, HttpRequest, HttpResponse} from "@angular/common/http";
import {finalize} from "rxjs";
import {tap} from "rxjs/operators";
import {inject} from "@angular/core";
import {LoadingService} from "../shared/commons/loader/loading.service";

export const IGNORE_LOADING = new HttpContextToken<boolean>(() => false);
const stack: any[] = [];

export function loadingInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  const loadingService = inject(LoadingService);
  console.log('IGNORE_LOADING: ' + IGNORE_LOADING);
  if (req.context.get(IGNORE_LOADING)) {
    console.log("load {1}");
    return next(req)
  }
  if (req.url?.includes('openid-connect')) {
    console.log("load {2}");
    return next(req)
  }
  if (req.url?.includes('actuator')) {
    console.log("load {3}");
    return next(req)
  }
  loadingService.show();
  stack.push(1);
  return next(req)
    .pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          stack.pop();
          if (!stack.length) {
            setTimeout(() => loadingService.hide());
          }
        }
      }, (err: HttpErrorResponse) => {
        setTimeout(() => loadingService.hide());
      }),
      finalize(() => {
        setTimeout(() => loadingService.hide());
      })
    );
}
