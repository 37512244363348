<form #form=ngForm class="form-validate" (ngSubmit)="save()">
  <div class="row">
    <div class="card custom-box my-3 border-0 ">
      <div class="card-body">
        <div class="row mt-3">
          <div class="text-center">
            <img src="assets/images/logo_acfs.png" class="img-card">
          </div>
        </div>
        <div class="row mt-3">
          <div class="text-center">
            <h2>
              ระบบตามสอบสินค้าเกษตรบนระบบคลาวด์
              <br>
              (QR Trace on Cloud)
            </h2>
          </div>
        </div>
        <div class="row mt-5 mb-4">
          <div class="col-5 text-end">
            <label class="ui-require">ชื่อ / Name</label>
          </div>
          <div class="col-7 text-start">
            <label>{{ thaiD.given_name }}</label>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-5 text-end">
            <label class="ui-require">นามสกุล / Surname</label>
          </div>
          <div class="col-7 text-start">
            <label>{{ thaiD.family_name }}</label>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-5 text-end">
            <label class="ui-require">เบอร์ติดต่อ / Telephone</label>
          </div>
          <div class="col-7 text-start">
            <input #mobile="ngModel" type="text" digitOnly id="mobile" minlength="10" maxlength="10" name="mobile" [(ngModel)]="param.mobile" class="form-control w-50" required>
            @if (mobile.invalid && (mobile.dirty || mobile.touched)) {
              <div class="ng-invalid-feedback">
                @if (mobile.errors?.['required']){
                  <div>กรุณากรอกเบอร์มือถือ</div>
                }
                @if (mobile.errors?.['minlength']){
                  <div>กรุณากรอกเบอร์มือถือ 10 หลัก</div>
                }
              </div>
            }
          </div>
        </div>
        @if (!thaiD.registerUuid) {
          <div class="row mb-4">
            <div class="col-5 text-end">
              <label class="ui-require">e-Mail</label>
            </div>
            <div class="col-7 text-start">
              <input #email="ngModel" type="text" id="email" name="email" [pattern]="emailPattern" [(ngModel)]="param.email" class="form-control w-50" required>
              @if (email.invalid && (email.dirty || email.touched)) {
                <div class="ng-invalid-feedback">
                  @if (email.errors?.['required']){
                    <div>กรุณากรอกอีเมล</div>
                  }
                  @if (email.errors?.['pattern']){
                    <div>อีเมลไม่ถูกต้อง</div>
                  }
                </div>
              }
            </div>
          </div>
        }
        <div class="row mb-4">
          <div class="col-5 text-end">
            <label class="ui-require">ชื่อผู้ใช้งาน / Username</label>
          </div>
          <div class="col-7 text-start">
            <input type="text" id="username" name="username" [(ngModel)]="param.username" class="form-control w-50" required>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-5 text-end">
            <label class="ui-require">ระบุรหัสผ่าน / Password</label>
          </div>
          <div class="col-7 text-start">
            <div class="input-group w-50">
              <div class="input-group-prepend">
                    <span class="input-group-text">
                     <i class="bi bi-lock-fill fs-5"></i>
                    </span>
              </div>
              <input id="password" name="password"
                     [pattern]="passwordPattern"
                     [(ngModel)]="param.password" [type]="visibleConfirm ? 'text' : 'password'"
                     class="form-control input-pass" required>
              <div class="input-group-prepend">
                    <span class="input-group-text-pass fs-5" (click)="viewPassword('confirm')">
                     <i [ngClass]="visibleConfirm ? 'bi bi-eye fs-5' : 'bi bi-eye-slash-fill fs-5' "></i>
                    </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4 mt-2">
          <div class="col-5"></div>
          <div class="col-4 text-start text-muted">
            รหัสผ่านมีความยาว 6 ตัวอักษรขึ้นไป ประกอบด้วยตัวอักษรทั้งตัวพิมพ์ใหญ่ ตัวพิมพ์เล็ก และตัวเลข
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-5 text-end">
            <label class="ui-require">ระบุรหัสผ่านอีกครั้ง / Confirm Passoword</label>
          </div>
          <div class="col-7 text-start">
            <div class="input-group w-50">
              <div class="input-group-prepend">
                    <span class="input-group-text">
                     <i class="bi bi-lock-fill fs-5"></i>
                    </span>
              </div>
              <input id="confirm-password" name="confirm-password"
                     [(ngModel)]="confirmPassword"
                     [pattern]="passwordPattern"
                     [type]="visibleReConfirm ? 'text' : 'password'" class="form-control input-pass" required>
              <div class="input-group-prepend">
                    <span class="input-group-text-pass" (click)="viewPassword('reconfirm')">
                     <i [ngClass]="visibleReConfirm ? 'bi bi-eye fs-5' : 'bi bi-eye-slash-fill fs-5' "></i>
                    </span>
              </div>
            </div>
            @if (param.password && confirmPassword && param.password !== confirmPassword) {
              <div class="ng-invalid-feedback">
                <div>รหัสผ่านไม่ตรงกัน</div>
              </div>
            }
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-5">
          </div>
          <div class="col-7 text-start">
            <button type="submit"
                    class="btn btn-custom btn-custom-theme btn-thaid me-2 w-50">
              ยืนยันการลงทะเบียน
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
