import {CanActivateFn, Router, UrlTree} from '@angular/router';
import {inject} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {ResourceAccess} from '../app.constants';
import {map, Observable} from 'rxjs';

export const canAccessAppGuard: CanActivateFn = (route, state) => {
  const oidcSecurityService = inject(OidcSecurityService);
  const router = inject(Router);

  return oidcSecurityService.getPayloadFromAccessToken(false).pipe(
    map((payload) => {
      if (payload && (payload as ResourceAccess)?.resource_access && (payload as ResourceAccess)?.resource_access?.['qrtraces']) {
        return true;
      } else {
        return router.createUrlTree(['403']);
      }
    })
  );
};
