<div class="card custom-box my-3 border-0 ">
  <div class="card-body">
    <div class="row mt-3">
      <div class="text-center">
        <img src="assets/images/logo_acfs.png" class="img-card">
      </div>
    </div>
    <div class="row mt-5">
      <div class="ps-5 pe-5 text-start message-register">
        นโยบายความเป็นส่วนตัว<br>
        สำนักงานมาตรฐานสินค้าเกษตรและอาหารแห่งชาติ (“สำนักงาน”) ขอแนะนำให้ท่านทำความเข้าใจนโยบายส่วนบุคคล (privacy
        policy) นี้ เนื่องจาก นโยบายนี้อธิบายถึงวิธีการที่สำนักงานปฏิบัติต่อข้อมูลส่วนบุคคลของท่าน เช่น
        การเก็บรวบรวม
        การจัดเก็บรักษา การใช้ การเปิดเผย รวมถึงสิทธิต่าง ๆ ของท่าน เป็นต้น
        เพื่อให้ท่านได้รับทราบถึงนโยบายในการคุ้มครองข้อมูลส่วนบุคคลของสำนักงาน สำนักงานจึงประกาศนโยบายส่วนบุคคล
        ดังต่อไปนี้
        <br>
        <br>
        <span style="margin-left:3em">
              1. สำนักงานเคารพและให้ความสำคัญถึงสิทธิ ข้อมูลส่วนบุคคลและการคุ้มครองข้อมูลส่วนบุคคลของท่าน และ
              สำนักงานตระหนักดีว่า ท่านในฐานะผู้ใช้บริการธุรกรรมทางอิเล็กทรอนิกส์ของสำนักงาน
              หรือบริการอื่นใดของสำนักงานด้วยวิธีการทางอิเล็กทรอนิกส์
              ย่อมมีความประสงค์ที่จะได้รับความมั่นคงปลอดภัยในการใช้บริการผ่านเว็บไซต์ของสำนักงาน
            </span>
        <br>
        <span style="margin-left:3em">
                2. ข้อมูลส่วนบุคคลของท่านที่สำนักงานได้รับมา เช่น ชื่อ ที่อยู่ หมายเลขโทรศัพท์ เลขประจำตัวประชาชน
              เป็นต้น ซึ่งสามารถบ่งบอกตัวบุคคลของท่านได้ และเป็นข้อมูลส่วนบุคคลที่มีความสมบูรณ์ ถูกต้อง เป็นปัจจุบัน และมีคุณภาพ
              จะถูกนำไปใช้ให้เป็นไปตามวัตถุประสงค์การดำเนินงานของสำนักงานเท่านั้น และสำนักงานจะดำเนินมาตรการที่เข้มงวดในการรักษาความมั่นคงปลอดภัย
              ตลอดจนการป้องกันมิให้มีการนำข้อมูลส่วนบุคคลไปใช้โดยมิได้รับอนุญาตจากท่านก่อน
            </span>
      </div>
    </div>
    <div class="row mt-5 mb-4">
      <div class="text-end">
        <button type="button"
                class="btn btn-custom btn-custom-theme me-2"
                (click)="next()">
          รับทราบ
        </button>
        <button type="button"
                class="btn btn-custom btn-light me-2"
                (click)="back()">
          ยกเลิก
        </button>
      </div>
    </div>
  </div>
</div>
