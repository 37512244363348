<div class="container">
  <div class="row">
    <div class="col-xxl-4 d-none d-xxl-block my-auto text-center">
      <img src="assets/images/login.png" class="img">
    </div>
    <div class="col-xxl-8 col-md-12">
      <div class="title">
        Welcome : ระบบตามสอบสินค้าเกษตร <br> บนระบบคลาวด์ (QR Trace on Cloud)
      </div>
      <div class="card custom-box my-3 border-0 card-login">
        <div class="card-body">
          <div class="row">
            <div class="col-xxl-4 col-md-12">
              <img src="assets/images/logo_acfs.png" class="logo">
            </div>
            <div class="col-xxl-8 col-md-12">
              <div class="row mt-5">
                <div class="text-center">
                  <button type="button"
                          class="btn btn-custom btn-custom-theme me-2 w-100"
                          (click)="login()">
                    <i class="bi bi-key fs-5 me-1"></i>
                    Login เข้าใช้งานระบบ
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-title">
        ท่านสามารถลงทะเบียนสมัครสมาชิกเพื่อเข้าสู่ระบบตามสอบสินค้าเกษตร<br>บนระบบคลาวด์ (QR Trace on Cloud)
        โดยต้องทำการพิสูจน์ตัวตนผ่าน ThaID <br>และ ทางอีเมลที่ระบบจัดส่งให้
        โดยคลิกที่ <a routerLink="/register" style="font-size: 1.5rem">ลงทะเบียน</a>
      </div>
    </div>
  </div>
  <div class="row text-center mb-3">
    <div class="text-muted">
      Open Data สำหรับให้บริการประเภท Web Service โดย สำนักงานมาตรฐานสินค้าเกษตรและอาหารแห่งชาติ
    </div>
  </div>
</div>


