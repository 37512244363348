<div class="forbidden-container">
  <div class="forbidden-content">
    <div class="forbidden-icon">
      <i class="fas fa-ban"></i>
    </div>
    <h1 class="forbidden-title">Access Denied</h1>
    <p class="forbidden-message">
      You do not have permission to access this page.
    </p>
    <p class="forbidden-message">
      Please contact your administrator if you believe this is an error.
    </p>
    <button class="forbidden-logout-button" (click)="logout()">Logout</button>
  </div>
</div>
