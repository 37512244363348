import {PassedInitialConfig} from 'angular-auth-oidc-client';
import {environment} from "../../environments/environment";

export const authConfig: PassedInitialConfig = {
    config: {
        secureRoutes: [environment.serverUrl, ''],
        authority: environment.oidcIssuerUrl,
        redirectUrl: `${window.location.origin}/oidc-callback`,
        postLogoutRedirectUri: window.location.origin,
        clientId: environment.clientId,
        scope: 'openid profile email', // 'openid profile offline_access ' + your scopes
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        autoUserInfo: true,
        renewUserInfoAfterTokenRenew: true,
        ignoreNonceAfterRefresh: true,
        renewTimeBeforeTokenExpiresInSeconds: 60,
        startCheckSession: true,
        triggerRefreshWhenIdTokenExpired: true,
        logLevel: environment.ngOidcLogLevel,
    }
}
