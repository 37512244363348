import {Routes} from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {RegisterComponent} from './pages/register/register.component';
import {RegisterThaidComponent} from './pages/register/register-thaid/register-thaid.component';
import {ThaidComponent} from './pages/register/thaid/thaid.component';
import {CreateUserComponent} from './pages/register/create-user/create-user.component';
import {EmailVerificationComponent} from './pages/register/email-verification/email-verification.component';
import {ConfirmPasswordComponent} from './pages/register/confirm-password/confirm-password.component';
import {RegisterFinishComponent} from './pages/register/register-finish/register-finish.component';
import {RegisterPolicyComponent} from './pages/register/register-policy/register-policy.component';
import {LayoutComponent} from './pages/layout/layout.component';
import {ManufacturerComponent} from './pages/manufacturer/manufacturer.component';
import {ManagerUserComponent} from './pages/manager-user/manager-user.component';
import {ManagerQrcodeComponent} from './pages/manager-qrcode/manager-qrcode.component';
import {SearchComponent} from './pages/search/search.component';
import {FormComponent} from './pages/form/form.component';
import {OidcCallbackComponent} from './auth/oidc-callback/oidc-callback.component';
import {autoLoginPartialRoutesGuard} from 'angular-auth-oidc-client';
import {ForbiddenComponent} from './auth/forbidden/forbidden.component';
import {canAccessAppGuard} from './auth/can-access-app.guard';
import {VerifyThaidComponent} from './pages/register/verify-thaid/verify-thaid.component';
import {WelcomeComponent} from './pages/welcome/welcome.component';
import {hasManufacturerGuard} from './shared/guard/has-manufacturer.guard';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'welcome'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent,
    children: [
      {
        path: '',
        component: RegisterPolicyComponent
      },
      {
        path: 'start-thaid',
        component: RegisterThaidComponent
      },
      {
        path: 'thaid',
        component: ThaidComponent
      },
      {
        path: 'create',
        component: CreateUserComponent
      },
      {
        path: 'verify',
        component: EmailVerificationComponent
      },
      {
        path: 'confirm',
        component: ConfirmPasswordComponent
      },
      {
        path: 'finish',
        component: RegisterFinishComponent
      },
      {
        path: 'verify-thaid',
        component: VerifyThaidComponent
      },
      {
        path: 'verify-thaid-callback',
        component: VerifyThaidComponent
      },
    ]
  },
  {
    path: 'p',
    loadChildren: () => import('./pages/public/public.module').then(m => m.PublicModule)
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [autoLoginPartialRoutesGuard,canAccessAppGuard],
    children: [
      {
        path: 'welcome',
        component: WelcomeComponent,
      },
      {
        path: 'form',
        component: FormComponent,
        canActivate: [autoLoginPartialRoutesGuard,hasManufacturerGuard],
        loadChildren: () => import('./pages/form/form.module').then(m => m.FormModule)
      },
      {
        path: 'manufacturer',
        component: ManufacturerComponent,
        canActivate: [autoLoginPartialRoutesGuard],
        data: {title: 'ข้อมูลผู้ประกอบการ', srcIcon: '/assets/icons/user.svg'}
      },
      {
        path: 'manager',
        component: ManagerUserComponent,
        data: {title: 'ข้อมูลผู้ใช้งานกลุ่ม/บริษัท'}
      },
      {
        path: 'master',
        canActivate: [hasManufacturerGuard],
        loadChildren: () => import('./pages/master/master.module').then(m => m.MasterModule)
      },
      {
        path: 'qrcode',
        component: ManagerQrcodeComponent,
        data: {title: 'การจัดการ QR Code'}
      },
      {
        path: 'report',
        loadChildren: () => import('./pages/report/report.module').then(m => m.ReportModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: 'search',
        component: SearchComponent,
        data: {title: 'ค้นหาวัตถุดิบจากผู้ประกอบการรายอื่นในระบบ QR Trace on Cloud'}
      },
    ]
  },
  {
    path:'oidc-callback',
    component: OidcCallbackComponent
  },
  {
    path:'403',
    component: ForbiddenComponent
  }
];
