<div class="card custom-card my-3 border-0">
  <div class="card-body mb-3">
    <div class="row mb-3">
      <label class="col-form-label col-auto">
        <i class="bi bi-search me-2"></i>
        ค้นหาข้อมูล
      </label>
    </div>
    <hr>
    <div class="row mb-1 ps-4 pe-4">
      <div class="col-xl-4 col-sm-12 mt-3">
        <div>
          <label>
            ชื่อผู้ใช้งาน / Username
          </label>
        </div>
        <div>
          <input type="text" [(ngModel)]="searchParams().userName" class="form-control" name="userName" id="userName">
        </div>
      </div>
      <div class="col-xl-4 col-sm-12 mt-3">
        <div>
          <label>
            ชื่อ - นามสกุล / FullName
          </label>
        </div>
        <div>
          <input type="text" class="form-control" name="fullName" id="fullName" [(ngModel)]="searchParams().fullName">
        </div>
      </div>
      <div class="col-xl-4 col-sm-12 mt-3">
        <div>
          <label>
            สถานะ / Status
          </label>
        </div>
        <div>
          <select class="form-select" [(ngModel)]="searchParams().enable" id="enable" name="enable">
            <option [ngValue]="undefined">--- เลือกทั้งหมด ---</option>
            <option [ngValue]="true">ใช้งาน (Active)</option>
            <option [ngValue]="false">ไม่ใช้งาน (InActive)</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row mb-3 mt-5">
      <div class="col-12 text-start">
        <div class="text-center">
          <button type="button"
                  class="btn btn-custom btn-custom-theme me-2"
          (click)="search()">
            <i class="bi bi-search me-2"></i>
            ค้นหา
          </button>
          <button type="button"
                  class="btn btn-custom btn-light me-2"
          (click)="clear()">
            <i class="bi bi-arrow-clockwise me-2"></i>
            ล้างข้อมูล
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card custom-card my-3 border-0">
  <div class="card-body mb-3">
    <div class="table-responsive">
      <table class="table table-striped table-borderless caption-top">
        <caption>
          รายการทั้งหมด {{ pageData()?.totalElements | number }} รายการ
          <button class="btn btn-custom btn-custom-theme float-end" (click)="openModel('เพิ่ม')" *hasAnyRole="['user_admin']">
            <i class="bi bi-plus-lg me-1"></i>
            เพิ่มผู้ใช้งาน (New)
          </button>
        </caption>
        <thead class="color-black font-weight-light">
        <tr class="table-light text-center" style="background-color: #eeeeee;">
          <th nowrap scope="col" class="">ลำดับ /<br> No.</th>
          <th nowrap scope="col" class=""></th>
          <th nowrap scope="col" class="">ชื่อผู้ใช้งาน /<br> Username</th>
          <th nowrap scope="col" class="">ชื่อ - นามสกุล /<br> FullName</th>
          <th nowrap scope="col" class="">เบอร์ติดต่อ /<br> Telephone</th>
          <th nowrap scope="col" class="">อีเมล /<br> Email</th>
          <th nowrap scope="col" class="">สถานะ /<br> Status</th>
          <th nowrap scope="col" class="">บทบาท /<br> Role</th>

        </tr>
        </thead>
        <tbody class="table-borderless">
        <tr *ngIf="!pageData()?.content?.length">
          <td class="text-muted text-center" colspan="7">
            <app-not-found></app-not-found>
          </td>
        </tr>
        <tr *ngFor="let item of pageData()?.content; let i= index">
          <td class="text-center">{{i+1}}</td>
          <td class="text-center">
            <button class="btn btn-default" *hasAnyRole="['user_admin']">
              <i class="bi bi-pencil"></i>
            </button>
          </td>
          <td class="text-center">{{item.userName}}</td>
          <td class="text-center">{{item?.fullName}}</td>
          <td class="text-center">{{item.manufacturer.telephone}}</td>
          <td class="text-center">{{item.email}}</td>
          @if(item.enable){
            <td class="text-center">ใช้งาน (ACTIVE)</td>
          } @else {
            <td class="text-center">ไม่ใช้งาน (IN ACTIVE)</td>

          }
          <td class="text-center">{{item.manufacturerAdmin? 'Admin' : 'User'}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <ngb-pagination class="d-flex justify-content-center"
                    [maxSize]="5"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    [page]="pageData()?.number + 1"
                    (pageChange)="search($event)"
                    [pageSize]="pageData()?.size"
                    [collectionSize]="pageData()?.totalElements"></ngb-pagination>
  </div>
</div>

