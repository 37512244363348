<div class="modal-body">
  <div class="row mb-1 ps-4 pe-4">
    <label class="col-xl-12 col-sm-12 fs-4 text-center">แบบสอบถามความพึงพอใจในการใช้บริการ</label>
    <div class="col-xl-12 col-sm-12 mb-3 text-center">
      <div id="satisfied-resource" class="btn-group btn-group-toggle"
           ngbRadioGroup>
        <ng-container>
          <label class="btn" ngbButtonLabel>
            <input type="radio" ngbButton name="satisfied-resource"
                   [value]="'DISPLEASED'">
            <i class="bi bi-emoji-angry text-danger" style="font-size: 3rem"></i>
            <br>
            <label class="fs-5 text-center">ไม่พอใจ</label>
          </label>
          <label class="btn" ngbButtonLabel>
            <input type="radio" ngbButton name="satisfied-resource"
                   [value]="'SLIGHTLY'">
            <i class="bi bi-emoji-frown text-warning" style="font-size: 3rem"></i>
            <br>
            <label class="fs-5 text-center">น้อย</label>
          </label>
          <label class="btn" ngbButtonLabel>
            <input type="radio" ngbButton name="satisfied-resource"
                   [value]="'MODERATELY'">
            <i class="bi bi-emoji-neutral text-hint" style="font-size: 3rem"></i>
            <br>
            <label class="fs-5 text-center">ปานกลาง</label>
          </label>
          <label class="btn" ngbButtonLabel>
            <input type="radio" ngbButton name="satisfied-resource"
                   [value]="'VERY'">
            <i class="bi bi-emoji-smile text-success" style="font-size: 3rem"></i>
            <br>
            <label class="fs-5 text-center">มาก</label>
          </label>
        </ng-container>
      </div>
    </div>
    <div class="col-xl-12 col-sm-12 mb-3">
      <textarea placeholder="ระบุความคิดเห็นเพิ่ม (ถ้ามี)"
                class="form-control"></textarea>
    </div>
  </div>
</div>
<div class="row mt-3 mb-4 pe-3">
  <div class="col-12 text-center">
    <button type="button" class="btn btn-custom btn-custom-theme mx-1 btn-main confirm-btn" (click)="sentForm()">
      ส่งแบบสอบถาม
    </button>
    <button type="button" class="btn btn-custom btn-light mx-1 btn-main confirm-btn" (click)="activeModal.close()">
      ยกเลิก
    </button>
  </div>
</div>
