import {Component, inject, OnInit} from '@angular/core';
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {NotFoundComponent} from "../../shared/not-found/not-found.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Page, QrCodeDto, QrCodeSearchParam, QrCodeStatus, QrCodeStatusArray} from '../../../generated-model/model';
import {SharedCommonModule} from '../../shared/shared-common.module';
import {QrCodeRest} from '../../service/QrCodeRest';
import {IgnoreNullHttpParams} from '../../shared/Ignore-null-http-params';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-manager-qrcode',
  standalone: true,
  imports: [
    DecimalPipe,
    NgForOf,
    NgIf,
    NgbPagination,
    NotFoundComponent,
    ReactiveFormsModule,
    SharedCommonModule,
    FormsModule
  ],
  templateUrl: './manager-qrcode.component.html',
  styleUrl: './manager-qrcode.component.scss'
})
export class ManagerQrcodeComponent implements OnInit{
  pageData: Page<QrCodeDto> = {size: 10};
  searchParam : QrCodeSearchParam & {page? : number} = {}
  private modalService = inject(NgbModal);

  qrCodeRest = inject(QrCodeRest)

  ngOnInit(): void {
    this.search()
  }

  search(page: number = 1) {
    let param = IgnoreNullHttpParams.fromObject({...this.searchParam})
      .set('page', page - 1)
      .set('size', this.pageData.size)
      .set('sort', 'createdDate,desc')
    this.qrCodeRest.searchAdmin(param).pipe(filter(e => !!e)).subscribe({
      next: e => {
        this.pageData = e
      }
    })
  }

  protected readonly QrCodeStatus = QrCodeStatus;
  protected readonly QrCodeStatusArray = QrCodeStatusArray;

  clear() {
    this.searchParam = {}
    this.search()
  }
}
