<div class="row">
  <div class="card custom-box my-3 border-0 ">
    <div class="card-body">
      <div class="row mt-3">
        <div class="text-center">
          <img src="assets/images/logo_acfs.png" class="img-card">
        </div>
      </div>
      <div class="row mt-3">
        <div class="text-center">
          <h2>
            ระบบตามสอบสินค้าเกษตรบนระบบคลาวด์
            <br>
            (QR Trace on Cloud)
          </h2>
        </div>
      </div>
      <div class="row mt-3">
        <div class="text-center">

        </div>
      </div>
      <div class="row mt-3 mb-4">
        <div class="col-5 text-end">
          <label class="ui-require">ระบุรหัสผ่าน / Password</label>
        </div>
        <div class="col-7 text-start">
          <div class="input-group w-50">
            <div class="input-group-prepend">
                    <span class="input-group-text">
                     <i class="bi bi-lock-fill fs-5"></i>
                    </span>
            </div>
            <input [type]="visibleConfirm ? 'text' : 'password'" class="form-control input-pass">
            <div class="input-group-prepend">
                    <span class="input-group-text-pass fs-5" (click)="viewPassword('confirm')">
                     <i [ngClass]="visibleConfirm ? 'bi bi-eye fs-5' : 'bi bi-eye-slash-fill fs-5' "></i>
                    </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-5 text-end">
          <label class="ui-require">ระบุรหัสผ่านอีกครั้ง / Confirm Passoword</label>
        </div>
        <div class="col-7 text-start">
          <div class="input-group w-50">
            <div class="input-group-prepend">
                    <span class="input-group-text">
                     <i class="bi bi-lock-fill fs-5"></i>
                    </span>
            </div>
            <input [type]="visibleReConfirm ? 'text' : 'password'" class="form-control input-pass">
            <div class="input-group-prepend">
                    <span class="input-group-text-pass" (click)="viewPassword('reconfirm')">
                     <i [ngClass]="visibleReConfirm ? 'bi bi-eye fs-5' : 'bi bi-eye-slash-fill fs-5' "></i>
                    </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-5">
        </div>
        <div class="col-7 text-start">
          <button type="button"
                  class="btn btn-custom btn-custom-theme btn-thaid me-2 w-50"
                  (click)="next()">
            ยืนยัน
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
