<div class="row">
  <div class="card custom-box my-3 border-0 ">
    <div class="card-body">
      <div class="row mt-3">
        <div class="text-center">
          <img src="assets/images/logo_acfs.png" class="img-card">
        </div>
      </div>
      <div class="row mt-3">
        <div class="text-center">
          <h2>
            ระบบตามสอบสินค้าเกษตรบนระบบคลาวด์
            <br>
            (QR Trace on Cloud)
          </h2>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="text-center">
          <button type="button"
                  class="btn btn-custom btn-custom-theme btn-thaid me-2 w-75"
                  (click)="next()">
            คลิก เพื่อยืนยันตัวตนด้วยแอปพลิเคชั่น ThaID
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
