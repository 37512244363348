import { Component } from '@angular/core';

@Component({
  selector: 'app-oidc-callback',
  standalone: true,
  imports: [],
  template:''
})
export class OidcCallbackComponent {

}
