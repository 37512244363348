import {Component} from '@angular/core';
import {ReactiveFormsModule} from "@angular/forms";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {Page} from '../../../generated-model/model';
import {DecimalPipe, NgForOf, NgIf} from '@angular/common';
import {NotFoundComponent} from '../../shared/not-found/not-found.component';
import {ManagerUserModalComponent} from './manager-user-modal/manager-user-modal.component';

@Component({
  selector: 'app-manager-user',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgbPagination,
    NgForOf,
    NgIf,
    NotFoundComponent,
    DecimalPipe
  ],
  templateUrl: './manager-user.component.html',
  styleUrl: './manager-user.component.scss'
})
export class ManagerUserComponent {
  pageData: Page<any> = {size: 10};

  constructor(private modalService: NgbModal) {
  }

  search(page: number = 1) {
  }

  openModel(prefix: string) {
    if (prefix == 'เพิ่ม') {
      const modalRef = this.modalService.open(ManagerUserModalComponent, {
        windowClass: 'set-width-modal',
      });
      modalRef.componentInstance.prefix = 'ระบุ e-Mail เพื่อเพิ่มผู้ใช้งานในกลุ่ม/บริษัท'
    }
  }
}
