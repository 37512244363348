import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EmailRegisterDto, Page, UserManageGroupDto, UserManageGroupSearchParams} from '../../generated-model/model';
import {environment} from '../../environments/environment';
import {IgnoreNullHttpParams} from '../shared/Ignore-null-http-params';

@Injectable({providedIn: 'root'})
export class UserRest{

  http = inject(HttpClient)

  searchGroup(params:IgnoreNullHttpParams){
    return this.http.get<Page<UserManageGroupDto>>(`${environment.serverUrl}/api/user/search-group`,{params:params.toHttpParam()})
  }

  saveInviteUser(emailDto:EmailRegisterDto){
    return this.http.post(`${environment.serverUrl}/api/user/invite-user?`,emailDto)
  }
}
