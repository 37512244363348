import {Component, inject} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';

@Component({
  selector: 'app-forbidden',
  standalone: true,
  imports: [],
  templateUrl: './forbidden.component.html',
  styleUrl: './forbidden.component.scss'
})
export class ForbiddenComponent {
  private oidcSecurityService =   inject(OidcSecurityService);
  constructor() {
  }

  logout() {
    console.log('logout')
    this.oidcSecurityService
      .logoff()
      .subscribe((result) => console.log(result));
  }
}
