import {
  Component, inject,
} from '@angular/core';
import {Router, RouterLink, RouterLinkActive, RouterOutlet} from "@angular/router";
import {LoadingComponent} from '../../shared/commons/loader/loading.component';
import {AppHeaderComponent} from '../../shared/commons/app-header/app-header.component';

@Component({
  selector: 'app-layout',
  standalone: true,
  templateUrl: './layout.component.html',
  imports: [
    RouterOutlet,
    LoadingComponent,
    AppHeaderComponent,
    RouterLinkActive,
    RouterLink
  ],
  styleUrl: './layout.component.scss'
})
export class LayoutComponent {
  private router = inject(Router);

  next() {
    this.router.navigate(['/register/finish']);
  }
}
