<div class="container-fluid">
  <div class="row">
    <div class="col-auto d-xl-block d-none p-0">
      <div id="sidebar" class="collapse show">
        <div id="sidebar-menu" tabindex="-1" aria-labelledby="sidebar-menu-label">
          <div class=" d-md-flex flex-column custom-side-bar">
            <ul class="nav flex-column">
              <li class="nav-item d-flex justify-content-center p-2 text-centerc">
                <a class="navbar-brand d-none d-md-flex">
                  <img src="/assets/images/logo_acfs.png" alt="Logo" class="">
                  <h6 class="text-logo my-auto">
                    ระบบตามสอบสินค้าเกษตร
                    บนระบบคลาวด์<br>
                    (QR Trace on Cloud)
                  </h6>
                </a>
              </li>
            </ul>
            <span class="custom-hr"><hr></span>
            <ng-container [ngTemplateOutlet]="sidebarContent"></ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="col-auto d-md-block d-xl-none p-0">
      <div id="sidebar-md" class="collapse">
        <div id="sidebar-menu-md" tabindex="-1" aria-labelledby="sidebar-menu-label">
          <div class="row">
            <div class="col-11 ps-5">
              <a class="navbar-brand d-flex d-xl-none">
                <img src="/assets/images/logo_acfs.png" alt="Logo" style="width: 50px;">
                <h6 class="text-logo my-auto">
                  ระบบตามสอบสินค้าเกษตร บนระบบคลาวด์ (QR Trace on Cloud)
                </h6>
              </a>
            </div>
            <div class="col-1 align-content-center text-end pe-5">
              <a data-bs-toggle="collapse"
                 data-bs-target="#sidebar-md"
                 aria-controls="sidebar-md"
                 aria-expanded="true"
                 aria-label="Toggle navigation">
                <i class="bi bi-x" style="font-size: 2rem;color: red;font-weight: bold"></i>
              </a>
            </div>
            <span class="custom-hr-md"><hr class="m-2"></span>
            <ng-container [ngTemplateOutlet]="sidebarContent"></ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="col overflow-y-auto p-0">
      <header id="header">
        <nav class="navbar navbar-expand">
          <div class="container-fluid">
            <button class="navbar-toggler d-xl-block d-none border-0"
                    type="button" data-bs-toggle="collapse"
                    data-bs-target="#sidebar"
                    aria-controls="sidebar"
                    aria-expanded="true"
                    aria-label="Toggle navigation">
              <i class="bi bi-list custom-icon-list"></i>
            </button>
            <button class="navbar-toggler d-xl-none d-block border-0"
                    type="button" data-bs-toggle="collapse"
                    data-bs-target="#sidebar-md"
                    aria-controls="sidebar-md"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
              <i class="bi bi-list custom-icon-list"></i>
            </button>
            <div class="mx-3">
              <div class="dropdown d-flex m-2">
                <div class="text-end d-block text-decoration-none me-5 my-auto fs-5 fw-bolder">
                  <button type="button"
                          class="btn btn-light"
                          (click)="feedBackModal()">
                    แบบสอบถาม<br>ความพึงพอใจในการใช้บริการ
                  </button>
                </div>
                <div class="text-end d-block text-decoration-none me-3 my-auto fs-5 fw-bolder">
                  {{ userData?.name }}
                </div>
                <div class="flex-shrink-0 dropdown">
                  <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="bi-person-circle fs-1 custom-icon-user"></i>
                  </a>
                  <ul class="dropdown-menu text-small" style="left :-95px">
                    <li><a class="dropdown-item" [href]="environment.accountInfoUrl"><i class="bi bi-person me-2"></i>โปรไฟล์</a>
                    </li>
                    <li><a class="dropdown-item" (click)="logout()"><i class="bi bi-box-arrow-in-right me-2"></i>ออกจากระบบ</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div id="content">
        <div class="ps-4 pt-4 pe-4 pb-0">
          <app-loading></app-loading>
          <app-header></app-header>
          <div>
            <router-outlet/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #sidebarContent>
  <div id="sidebar-content">
    <ul class="nav flex-column mt-1" customNavCollapse *hasAnyRole="['user']">
      <li class="nav-item">
        <a routerLink="/manufacturer" routerLinkActive="active"
           data-bs-toggle="collapse"
           data-bs-target="#sidebar-md"
           aria-controls="sidebar-md"
           aria-expanded="true"
           aria-label="Toggle navigation"
           class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2">
          <i class="bi bi-person-fill"></i>
          ข้อมูลผู้ประกอบการ
        </a>
      </li>
    </ul>
    <ul class="nav flex-column" customNavCollapse>
      @if (manufacturerRest?.manufacturer?.businessType && manufacturerRest?.manufacturer?.businessType !== 'FARMER') {
        <li class="nav-item" *hasAnyRole="['user']">
          <a routerLink="/manager" routerLinkActive="active"
             data-bs-toggle="collapse"
             data-bs-target="#sidebar-md"
             aria-controls="sidebar-md"
             aria-expanded="true"
             aria-label="Toggle navigation"
             class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
            <i class="bi bi-people-fill"></i>
            เพิ่มผู้ใช้งานในกลุ่ม/บริษัท
          </a>
        </li>
      }
      <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#master-data-collapse" *hasAnyRole="['user']">
        <a class="nav-link nav-link-collapse">
          <i class="bi bi-database"></i>
          <span>ข้อมูลหลัก (Master Data)</span>
          <i class="rtl:rotate-180 right-icon"
             x-bind:style="selected.includes('menu_style') ? 'transform:  rotate(90deg)' : ''">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
            </svg>
          </i>
        </a>
      </li>
      <div class="collapse" id="master-data-collapse" *hasAnyRole="['user']">
        <li class="nav-item">
          <a routerLink="/master/general" routerLinkActive="active"
             data-bs-toggle="collapse"
             data-bs-target="#sidebar-md"
             aria-controls="sidebar-md"
             aria-expanded="true"
             aria-label="Toggle navigation"
             class="nav-link btn d-flex align-items-center align-middle border-0 gap-2">
            ข้อมูลทะเบียนทั่วไป
          </a>
        </li>
        <li class="nav-item" *hasAnyRole="['user']">
          <a routerLink="/master/production" routerLinkActive="active"
             data-bs-toggle="collapse"
             data-bs-target="#sidebar-md"
             aria-controls="sidebar-md"
             aria-expanded="true"
             aria-label="Toggle navigation"
             class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
            ข้อมูลทะเบียนการผลิต
          </a>
        </li>
      </div>
      <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#form-collapse" *hasAnyRole="['user']">
        <a routerLink="/form" class="nav-link nav-link-collapse">
          <i class="bi bi-file-earmark-text-fill"></i>
          <span>แบบฟอร์ม</span>
          <i class="rtl:rotate-180 right-icon"
             x-bind:style="selected.includes('menu_style') ? 'transform:  rotate(90deg)' : ''">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
            </svg>
          </i>
        </a>
      </li>
      <div class="collapse" id="form-collapse">
        <li class="nav-item">
          <a routerLink="/form/plant" routerLinkActive="active"
             data-bs-toggle="collapse"
             data-bs-target="#sidebar-md"
             aria-controls="sidebar-md"
             aria-expanded="true"
             aria-label="Toggle navigation"
             class="nav-link btn d-flex align-items-center align-middle border-0 gap-2">
            ผัก/ผลไม้
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/form/rice" routerLinkActive="active"
             data-bs-toggle="collapse"
             data-bs-target="#sidebar-md"
             aria-controls="sidebar-md"
             aria-expanded="true"
             aria-label="Toggle navigation"
             class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
            ข้าว
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/form/egg" routerLinkActive="active"
             data-bs-toggle="collapse"
             data-bs-target="#sidebar-md"
             aria-controls="sidebar-md"
             aria-expanded="true"
             aria-label="Toggle navigation"
             class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
            ไข่
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/form/fishing" routerLinkActive="active"
             data-bs-toggle="collapse"
             data-bs-target="#sidebar-md"
             aria-controls="sidebar-md"
             aria-expanded="true"
             aria-label="Toggle navigation"
             class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
            ประมง
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/form/livestock" routerLinkActive="active"
             data-bs-toggle="collapse"
             data-bs-target="#sidebar-md"
             aria-controls="sidebar-md"
             aria-expanded="true"
             aria-label="Toggle navigation"
             class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
            ปศุสัตว์
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/form/processed" routerLinkActive="active"
             data-bs-toggle="collapse"
             data-bs-target="#sidebar-md"
             aria-controls="sidebar-md"
             aria-expanded="true"
             aria-label="Toggle navigation"
             class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
            อาหารแปรรูป
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/form/herbs" routerLinkActive="active"
             data-bs-toggle="collapse"
             data-bs-target="#sidebar-md"
             aria-controls="sidebar-md"
             aria-expanded="true"
             aria-label="Toggle navigation"
             class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
            สมุนไพร
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/form/processed-other" routerLinkActive="active"
             data-bs-toggle="collapse"
             data-bs-target="#sidebar-md"
             aria-controls="sidebar-md"
             aria-expanded="true"
             aria-label="Toggle navigation"
             class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
            ผลิตภัณฑ์แปรรูปอื่น (ที่ไม่ใช่อาหาร)
          </a>
        </li>
      </div>
      <li class="nav-item" *hasAnyRole="['admin','official']">
        <a routerLink="/qrcode" routerLinkActive="active"
           data-bs-toggle="collapse"
           data-bs-target="#sidebar-md"
           aria-controls="sidebar-md"
           aria-expanded="true"
           aria-label="Toggle navigation"
           class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
          <i class="bi bi-qr-code"></i>
          การจัดการ QR Code
        </a>
      </li>
      <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#report-collapse">
        <a class="nav-link nav-link-collapse">
          <i class="bi bi-graph-up"></i>
          <span>รายงาน</span>
          <i class="rtl:rotate-180 right-icon"
             x-bind:style="selected.includes('menu_style') ? 'transform:  rotate(90deg)' : ''">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
            </svg>
          </i>
        </a>
      </li>
      <div class="collapse" id="report-collapse">
        <li class="nav-item">
          <a routerLink="/report/dashboard" routerLinkActive="active"
             data-bs-toggle="collapse"
             data-bs-target="#sidebar-md"
             aria-controls="sidebar-md"
             aria-expanded="true"
             aria-label="Toggle navigation"
             class="nav-link btn d-flex align-items-center align-middle border-0 gap-2">
            Dashboard
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/report/all" routerLinkActive="active"
             data-bs-toggle="collapse"
             data-bs-target="#sidebar-md"
             aria-controls="sidebar-md"
             aria-expanded="true"
             aria-label="Toggle navigation"
             class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
            รายงานทั้งหมด
          </a>
        </li>
      </div>
      <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#manage-data-collapse" *hasAnyRole="['admin']">
        <a class="nav-link nav-link-collapse">
          <i class="bi bi-gear"></i>
          <span>การจัดการข้อมูล Admin</span>
          <i class="rtl:rotate-180 right-icon"
             x-bind:style="selected.includes('menu_style') ? 'transform:  rotate(90deg)' : ''">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
            </svg>
          </i>
        </a>
      </li>
      <div class="collapse" id="manage-data-collapse">
        <li class="nav-item" *hasAnyRole="['admin']">
          <a routerLink="/admin/user" routerLinkActive="active"
             data-bs-toggle="collapse"
             data-bs-target="#sidebar-md"
             aria-controls="sidebar-md"
             aria-expanded="true"
             aria-label="Toggle navigation"
             class="nav-link btn d-flex align-items-center align-middle border-0 gap-2">
            จัดการผู้ใช้งานระบบ
          </a>
        </li>
        <li class="nav-item" *hasAnyRole="['admin']">
          <a routerLink="/admin/qrcode" routerLinkActive="active"
             data-bs-toggle="collapse"
             data-bs-target="#sidebar-md"
             aria-controls="sidebar-md"
             aria-expanded="true"
             aria-label="Toggle navigation"
             class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
            จัดการการหมดอายุ QR Code
          </a>
        </li>
        <li class="nav-item" *hasAnyRole="['admin']">
          <a routerLink="/admin/register-group" routerLinkActive="active"
             data-bs-toggle="collapse"
             data-bs-target="#sidebar-md"
             aria-controls="sidebar-md"
             aria-expanded="true"
             aria-label="Toggle navigation"
             class="nav-link btn d-flex align-items-center align-middle border-0 gap-2">
            จัดการข้อมูลทะเบียนกลุ่มสินค้า
          </a>
        </li>
      </div>
      @if (manufacturerRest?.manufacturer?.businessType && manufacturerRest?.manufacturer?.businessType !== 'FARMER') {
        <li class="nav-item mb-5" *hasAnyRole="['user']">
          <a routerLink="/search" routerLinkActive="active"
             data-bs-toggle="collapse"
             data-bs-target="#sidebar-md"
             aria-controls="sidebar-md"
             aria-expanded="true"
             aria-label="Toggle navigation"
             class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
            <i class="bi bi-pc-display-horizontal"></i>
            ค้นหาวัตถุดิบ
          </a>
        </li>
      }
    </ul>
  </div>
</ng-template>
<app-toasts></app-toasts>
