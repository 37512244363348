import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {provideRouter, withComponentInputBinding, withInMemoryScrolling} from '@angular/router';

import {routes} from './app.routes';
import {I18n} from './shared/gt-ng-datepicker/custom-datepicker';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi} from '@angular/common/http';
import {AuthInterceptor, provideAuth} from 'angular-auth-oidc-client';
import {provideAnimations} from '@angular/platform-browser/animations';
import {loadingInterceptor} from './interceptors/loading_interceptor';
import {Http404Interceptor} from './shared/commons/interceptor/http404-interceptor';
import {Http5xxInterceptor} from './shared/commons/interceptor/http5xx-interceptor';
import {FeatherModule} from 'angular-feather';
import {allIcons} from 'angular-feather/icons';
import { authConfig } from './auth/auth.config';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withInMemoryScrolling({scrollPositionRestoration: 'top'}),
      withComponentInputBinding()
    ),
    I18n,
    provideHttpClient(
      // withFetch(),
      withInterceptors([
        loadingInterceptor,
      ]),
      withInterceptorsFromDi()
    ),
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: Http5xxInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: Http404Interceptor, multi: true},
    provideAnimations(),
    provideAnimationsAsync(),
    importProvidersFrom(FeatherModule.pick(allIcons)),
    provideAuth(authConfig)
  ]
};

