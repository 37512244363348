import {Component, inject} from '@angular/core';
import {Router} from '@angular/router';
import {NgClass} from '@angular/common';

@Component({
  selector: 'app-confirm-password',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './confirm-password.component.html',
  styleUrl: './confirm-password.component.scss'
})
export class ConfirmPasswordComponent {
  private router = inject(Router);
  visibleConfirm: boolean = false;
  visibleReConfirm: boolean = false;

  viewPassword(value: string) {
    if (value == 'confirm') {
      this.visibleConfirm = !this.visibleConfirm;
    }
    if (value == 'reconfirm') {
      this.visibleReConfirm = !this.visibleReConfirm;
    }

  }

  next() {
    this.router.navigate(['/register/finish']);
  }
}
