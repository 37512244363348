import {NgModule} from '@angular/core';
import {CommonModule, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {GtNgDatepickerModule} from "./gt-ng-datepicker/gt-ng-datepicker.module";
import {ThaiDateTimePipe} from "./thai-datetime.pipe";
import {RouterModule} from "@angular/router";
import {NgbModule, NgbPaginationModule, NgbScrollSpyModule, NgbScrollSpyService} from "@ng-bootstrap/ng-bootstrap";
import {NgSelectModule} from "@ng-select/ng-select";


@NgModule({
  declarations: [
    ThaiDateTimePipe,
  ],
  exports: [
    NgbModule,
    NgbScrollSpyModule,
    NgbPaginationModule,
    NgSelectModule,
    GtNgDatepickerModule,
    ThaiDateTimePipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    GtNgDatepickerModule,
    NgbModule,
    NgbScrollSpyModule,
    NgbPaginationModule,
    NgSelectModule,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: 'localStorage',
      useValue: window.localStorage
    },
    NgbScrollSpyService
  ]
})
export class SharedCommonModule {
}
