import {Component, inject, Input} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {Router} from '@angular/router';
import {SharedCommonModule} from '../../shared/shared-common.module';
import {FileUploader, FileUploadModule} from 'ng2-file-upload';
import {NgClass, NgIf} from '@angular/common';

@Component({
  selector: 'app-operator',
  standalone: true,
  imports: [
    FormsModule,
    SharedCommonModule,
    FileUploadModule,
    NgClass,
    NgIf
  ],
  templateUrl: './operator.component.html',
  styleUrl: './operator.component.scss'
})
export class OperatorComponent {
  private router = inject(Router);
  haveCode: boolean = false;
  isShopEtc: boolean = false;
  isStdEtc: boolean = false;
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  response: string;
  validates: string[];

  goToGoogleMap() {
    window.open('https://www.google.com/maps', "_blank");
  }

  changeValue($event: any) {
    this.haveCode = $event
  }

  toggleShopEtc() {
    this.isShopEtc = !this.isShopEtc;
  }

  toggleStdEtc() {
    this.isStdEtc = !this.isStdEtc;
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
}
