<div class="card custom-card my-3 border-0">
  <div class="card-body mb-3">
    <div class="row mb-3">
      <label class="col-form-label col-auto">
        <i class="bi bi-search me-2"></i>
        ค้นหาข้อมูล
      </label>
    </div>
    <hr>
    <div class="row mb-1 ps-4 pe-4">
      <div class="col-xl-6 col-sm-12 mt-3">
        <div>
          <label>
            ชื่อผู้ประกอบการ / Manufacturer
          </label>
        </div>
        <div>
          <input id="manufacturer" name="manufacturer" type="text" class="form-control" [(ngModel)]="searchParam.manufacturer">
        </div>
      </div>
      <div class="col-xl-6 col-sm-12 mt-3">
        <div>
          <label>
            รหัสสินค้า / Product Code
          </label>
        </div>
        <div>
          <input id="productCode" name="productCode" type="text" class="form-control" [(ngModel)]="searchParam.productCode">
        </div>
      </div>
      <div class="col-xl-6 col-sm-12 mt-3">
        <div>
          <label>
            ชื่อสินค้า / Product Name
          </label>
        </div>
        <div>
          <input id="productName" name="productName" type="text" class="form-control" [(ngModel)]="searchParam.productName">
        </div>
      </div>
      <div class="col-xl-6 col-sm-12 mt-3">
        <div>
          <label>
            ล็อตสินค้า / Product Lot
          </label>
        </div>
        <div>
          <input id="lotNo" name="lotNo" type="text" class="form-control" [(ngModel)]="searchParam.lotNo">
        </div>
      </div>
      <div class="col-xl-6 col-sm-12 mt-3">
        <div>
          <label>
            สถานะ / Status
          </label>
        </div>
        <div>
          <select  id="status" name="status"  class="form-select" [(ngModel)]="searchParam.status">
            <option [ngValue]="undefined">--- เลือกทั้งหมด ---</option>
            @for (status of QrCodeStatusArray; track status) {
              <option [ngValue]="status">{{ QrCodeStatus[status] }}</option>
            }
          </select>
        </div>
      </div>
    </div>
    <div class="row mb-3 mt-5">
      <div class="col-12 text-start">
        <div class="text-center">
          <button type="button" (click)="search()"
                  class="btn btn-custom btn-custom-theme me-2">
            <i class="bi bi-search me-2"></i>
            ค้นหา
          </button>
          <button type="button" (click)="clear()"
                  class="btn btn-custom btn-light me-2">
            <i class="bi bi-arrow-clockwise me-2"></i>
            ล้างข้อมูล
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card custom-card my-3 border-0">
  <div class="card-body mb-3">
    <div class="table-responsive">
      <table class="table table-striped table-borderless caption-top">
        <caption>
          รายการทั้งหมด {{ pageData?.totalElements | number }} รายการ
        </caption>
        <thead class="color-black font-weight-light">
        <tr class="table-light text-center" style="background-color: #eeeeee;">
          <th nowrap scope="col" class="">ลำดับ /<br> No.</th>
          <th nowrap scope="col" class="">ชื่อผู้ประกอบการ /<br> Manufacturer</th>
          <th nowrap scope="col" class="">รหัสสินค้า /<br> Product Code</th>
          <th nowrap scope="col" class="">ชื่อสินค้า /<br> Product Name</th>
          <th nowrap scope="col" class="">ล็อตสินค้า /<br> Product Lot</th>
          <th nowrap scope="col" class="">วันที่สร้าง /<br> Create Date</th>
          <th nowrap scope="col" class="">วันที่หมดอายุ /<br> EXP.</th>
          <th nowrap scope="col" class="">สถานะ /<br> Status</th>
        </tr>
        </thead>
        <tbody class="table-borderless">
        <tr *ngIf="!pageData?.content?.length">
          <td class="text-muted text-center" colspan="9">
            <app-not-found></app-not-found>
          </td>
        </tr>
        <tr *ngFor="let item of pageData?.content; let i= index">
          <td class="text-center">{{ (pageData.size * pageData.number) + (i + 1) }}</td>
          <td class="text-center">{{ item.businessName ?? '-' }}</td>
          @if (item.qrTypeCode == 'RM' || item.qrTypeCode == 'RF') {
            <td class="text-center">{{ item.productionActivities?.code ?? '-' }}</td>
            <td class="text-center">{{ item.productionActivities?.name ?? '-' }}</td>
            <td class="text-center">{{ item.productionActivities?.lotNo ?? '-' }}</td>
          } @else {
            @if (item.qrCodeStatus == 'PRE_QRCODE') {
              <td class="text-center">{{ item.preQrCodeProductCode ?? '-' }}</td>
              <td class="text-center">{{ '-' }}</td>
              <td class="text-center">{{ item.preQrCodeLotNo ?? '-' }}</td>
            } @else {
              <td class="text-center">{{ item.productCodePacking ?? '-' }}</td>
              <td class="text-center">{{ item.productNamePacking ?? '-' }}</td>
              <td class="text-center">{{ item.lotNoPacking ?? '-' }}</td>
            }
          }
          <td class="text-center">{{ item.createdDate | thaidatetime : {showTime: false} }}</td>
          <td class="text-center">{{ item.qrCodeExpireDate | thaidatetime : {showTime: false} }}</td>
          @if (item.qrCodeStatus == 'A') {
            @if (!item.expired) {
              <td class="text-center text-success">สร้าง QR Code แล้ว</td>
            } @else {
              <td class="text-center">หมดอายุ</td>
            }
          } @else if (item.qrCodeStatus == 'WAIT_QRCODE') {
            <td class="text-center text-danger">รอสร้าง QR Code</td>
          } @else if (item.qrCodeStatus == 'EXPIRE_QRCODE'){
            <td class="text-center">หมดอายุ</td>
          } @else if (item.qrCodeStatus == 'PRE_QRCODE'){
            <td class="text-center">
              <span class="custom-bg bg-pre-qrcode">QR Code ล่วงหน้า</span>
            </td>
          }
        </tr>
        </tbody>
      </table>
    </div>
    <ngb-pagination class="d-flex justify-content-center"
                    [maxSize]="5"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    [page]="pageData?.number + 1"
                    (pageChange)="search($event)"
                    [pageSize]="pageData?.size"
                    [collectionSize]="pageData?.totalElements"></ngb-pagination>
  </div>
</div>
