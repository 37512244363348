import {Component, inject} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-register-thaid',
  standalone: true,
  imports: [],
  templateUrl: './register-thaid.component.html',
  styleUrl: './register-thaid.component.scss'
})
export class RegisterThaidComponent {
  private router = inject(Router);

  next() {
    this.router.navigate(['/register/thaid']);
  }

}
