<div class="welcome-container">
  <div class="welcome-content">
    <div class="user-info">
      <div class="user-details">
        <h1 class="welcome-message">
          ยินดีต้อนรับ, {{ userData?.given_name }} {{ userData?.family_name }}
        </h1>
        @if (userData?.email) {
          <p class="user-email">
            {{ userData.email }}
          </p>
        }
        @if (isCountdownActive()) {
          <div class="countdown-container text-bg-info">
            <p class="countdown-text">
              ท่านยังไม่ได้ตั้งค่าผู้ประกอบการ ระบบกำลังนำท่านไปยังหน้าตั้งค่าข้อมูลผู้ประกอบการในอีก {{ countdown }} วินาที
            </p>
          </div>
        }
      </div>
    </div>
  </div>
</div>
