<div class="modal-header">
  <strong class="text-custom-theme bold fs-5 d-inline-block">{{ prefix }}</strong>
</div>
<form #form="ngForm" class="form-validate">
  <div class="modal-body">
    <div class="row mb-3 mt-4">
      <label class="col-3 col-form-label ui-require text-end">อีเมล / e-Mail</label>
      <div class="col-7">
        <input type="email" class="form-control" id="email" name="email"
               [(ngModel)]="emailDto.to" required
               email
               [class.is-invalid]="emailInput.invalid && (emailInput.dirty || emailInput.touched)"
               #emailInput="ngModel">
        @if (emailInput.invalid && (emailInput.dirty || emailInput.touched)) {
          <custom-error *ngIf="emailInput.hasError('required')" error="*กรุณาระบุอีเมล"></custom-error>
        }
      </div>

    </div>
  </div>
  <div class="row mt-3 mb-4 pe-3">
    <div class="col-12 text-center">
      <button type="button" class="btn btn-custom btn-custom-theme mx-1 btn-main confirm-btn" (click)="save()">
        <i class="bi bi-send me-1"></i>
        ส่ง e-Mail
      </button>
      <button type="button" class="btn btn-custom btn-light mx-1 btn-main confirm-btn" (click)="activeModal.close()">
        <i class="bi bi-x-lg me-1"></i>
        ยกเลิก
      </button>
    </div>
  </div>
</form>

