<div class="row">
  <div class="card custom-box my-3 border-0 ">
    <div class="card-body">
      <div class="row mt-3">
        <div class="text-center">
          <img src="assets/images/logo_acfs.png" class="img-card">
        </div>
      </div>
      <div class="row mt-3">
        <div class="text-center">
          <h2>
            ระบบตามสอบสินค้าเกษตรบนระบบคลาวด์
            <br>
            (QR Trace on Cloud)
          </h2>
        </div>
      </div>
      <div class="row mt-5 mb-4">
        <div class="text-center">
          <h3>Email verification</h3>
        </div>
      </div>
      <div class="row mb-4">
        <div class="custom-waring-box">
          <div class="alert alert-warning d-flex align-items-center p-5 w-75" role="alert">
            <i class="bi bi-exclamation-triangle custom-icon"></i>
            <h5>
              กรุณาตรวจสอบ e-Mail Address
              <a routerLink="/register/confirm" style="font-size: 1rem;">
                test&#64;gmail.com
              </a>
              เพื่อทำการ Verify e-Mail และกำหนดรหัสผ่าน
              สำหรับเข้าใช้งานระบบตามสอบสินค้าบนระบบคลาวด์
              (QR Trace on Cloud)
            </h5>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="text-center">
          <div>หากไม่ได้รับ e-Mail
            <a routerLink="/register/confirm">
              คลิกที่นี่
            </a>
            เพื่อส่ง e-Mail อีกครั้ง</div>
        </div>
      </div>
    </div>
  </div>
</div>
