<div class="card custom-card my-3 border-0">
  <form #form=ngForm class="form-validate">
    <div class="card-body">
      <div class="row mb-3">
        <label class="col-form-label col-auto">
          ข้อมูลผู้ติดต่อ / Contact User
        </label>
      </div>
      <hr>
      <div class="row mt-5 mb-3 ps-4 pe-4">
        <label for="fname" class="col-xl-3 text-xl-end text-sm-start col-form-label">ชื่อ-นามสกุล /Full Name
        </label>
        <div class="col-xl-3">
          <input class="form-control" id="fname" name="fname" [(ngModel)]="item().contractUser.fullName"
                 [disabled]="isDisable()">
          @if (!item().contractUser.fullName && !isDisable()) {
            <div class="my-2">
              <a (click)="fillContractUser()" class="link-primary description-from-type-label"> <i
                class="bi bi-person-plus-fill  me-2"></i>ใช้ข้อมูลจากผู้ใช้งานปัจจุบัน</a>
            </div>
          }
        </div>

        <label for="username" class="col-xl-3 col-sm-12 text-xl-end text-sm-start col-form-label">ชื่อผู้ใช้งาน /
          Username</label>
        <div class="col-xl-3">
          <input type="text" class="form-control" id="username" name="username"
                 [(ngModel)]="item().contractUser.userName" [disabled]="isDisable()">
        </div>
      </div>
      <div class="row mb-3 ps-4 pe-4">
        <label for="tel" class="col-xl-3 text-xl-end text-sm-start col-form-label">เบอร์ติดต่อ / Telephone</label>
        <div class="col-xl-3">
          <input type="text" class="form-control" id="tel" name="tel"
                 [(ngModel)]="item().contractUser.telephoneNo" [disabled]="isDisable()">
        </div>
        <label for="emailAdmin" class="col-xl-3 text-xl-end text-sm-start col-form-label">อีเมล /
          E-Mail</label>
        <div class="col-xl-3">
          <input type="email" class="form-control" id="emailAdmin" name="emailAdmin"
                 [(ngModel)]="item().contractUser.email" [disabled]="isDisable()">
        </div>
      </div>
      <hr>
      <div class="row mb-3">
        <label class="col-form-label col-auto">
          ข้อมูลทั่วไป / Information
        </label>
      </div>
      <hr>
      <div class="row mb-1 mt-5 ps-4 pe-4">
        <label for="manufacturerType" class="col-xl-3 ui-require text-xl-end text-sm-start col-form-label">
          เลือกประเภทผู้ประกอบการ / Manufacturer Type
        </label>
        <div class="col-xl-3 col-sm-12 mb-sm-3">
          <select class="form-select" id="manufacturerType" name="manufacturerType"
                  [(ngModel)]="item().manufacturerType" (change)="onManufacturerTypeChange()" required #mType=ngModel
                  [disabled]="notEditable()">
            <option [ngValue]="undefined">--- กรุณาเลือก ---</option>
            @for (item of manufacturerTypeArray; track item) {
              <option [ngValue]="item">{{ manufacturerType[item] }}</option>
            }
          </select>
          @if (mType.invalid && (mType.dirty || mType.touched)) {
            <custom-error *ngIf="mType.hasError('required')" error="*จำเป็น"></custom-error>
          }
        </div>
      </div>
      <div class="row mb-1 ps-4 pe-4">
        <label for="businessType" class="col-xl-3  ui-require text-xl-end text-sm-start col-form-label">
          เลือกประเภทผู้ใช้งาน / Business Type
        </label>
        <div class="col-xl-3 mb-sm-3">
          <select class="form-select" id="businessType" name="businessType" [(ngModel)]="item().businessType"
                  [disabled]="!!!item().manufacturerType||notEditable()" required #bType=ngModel>
            <option [ngValue]="undefined">--- กรุณาเลือก ---</option>
            @for (item of businessTypeArray; track item) {
              <option [ngValue]="item">{{ businessType[item] }}</option>
            }
          </select>
          @if (bType.invalid && (bType.dirty || bType.touched)) {
            <custom-error *ngIf="bType.hasError('required')" error="*จำเป็น"></custom-error>
          }
        </div>
        @if (item().manufacturerType == 'JURISTIC_PERSON') {
          <label for="juristicId" class="col-xl-3 ui-require text-xl-end text-sm-start col-form-label">
            เลขทะเบียนนิติบุคคล / Juristic ID.
          </label>
          <div class="col-xl-3  mb-sm-3">
            <input type="text" class="form-control" id="juristicId" name="juristicId" [(ngModel)]="item().juristicId"
                   required #juristicId=ngModel [disabled]="notEditable()">
            @if (juristicId.invalid && (juristicId.dirty || juristicId.touched)) {
              <custom-error *ngIf="juristicId.hasError('required')" error="*จำเป็น"></custom-error>
            }
          </div>
        } @else {
          <label for="pid" class="col-xl-3 ui-require text-xl-end text-sm-start col-form-label">
            เลขประจำตัวประชาชน / ID No.
          </label>
          <div class="col-xl-3  mb-sm-3">
            <input type="text" class="form-control" id="pid" name="pid" [(ngModel)]="item().pid" required #pid=ngModel
                   [disabled]="notEditable()">
            @if (pid.invalid && (pid.dirty || pid.touched)) {
              <custom-error *ngIf="pid.hasError('required')" error="*จำเป็น"></custom-error>
            }
          </div>
        }
      </div>
      <div class="row mb-1 ps-4 pe-4">
        <label for="businessName" class="col-xl-3 ui-require text-xl-end text-sm-start col-form-label">
          ชื่อกิจการ/บริษัท / Business Name
        </label>
        <div class="col-xl-3  mb-sm-3">
          <input type="text" class="form-control" id="businessName" name="businessName"
                 [(ngModel)]="item().businessName" required #businessName=ngModel [disabled]="isDisable()">
          @if (businessName.invalid && (businessName.dirty || businessName.touched)) {
            <custom-error *ngIf="businessName.hasError('required')" error="*จำเป็น"></custom-error>
          }
        </div>
        <label for="gln" class="col-xl-3 text-xl-end text-sm-start col-form-label">หมายเลข GLN (ถ้ามี) / Global
          Location Number</label>
        <div class="col-xl-3  mb-sm-3">
          <input type="text" class="form-control" id="gln" name="gln" [(ngModel)]="item().gln" [disabled]="isDisable()">
        </div>
      </div>
      <div class="row mb-1 ps-4 pe-4">
        <label for="website" class="col-xl-3  text-xl-end text-sm-start col-form-label">Website (ถ้ามี)</label>
        <div class="col-xl-3  mb-sm-3">
          <input type="text" placeholder="www.example.com" class="form-control" id="website" name="website"
                 [(ngModel)]="item().website" [disabled]="isDisable()">
        </div>
        <label for="email" class="col-xl-3  text-xl-end text-sm-start col-form-label">อีเมล / E-Mail</label>
        <div class="col-xl-3  mb-sm-3">
          <input type="text" class="form-control" id="email" name="email" [(ngModel)]="item().email"
                 [disabled]="isDisable()">
        </div>
      </div>
      <div class="row mb-5 ps-4 pe-4">
        <label for="telephone" class="col-xl-3  text-xl-end text-sm-start col-form-label">เบอร์ติดต่อ /
          Telephone</label>
        <div class="col-xl-3  mb-sm-3">
          <input type="text" class="form-control" id="telephone" name="telephone" [(ngModel)]="item().telephone"
                 [disabled]="isDisable()">
        </div>
        <label for="fax" class="col-xl-3  text-xl-end text-sm-start col-form-label">แฟกซ์ / Fax</label>
        <div class="col-xl-3  mb-sm-3">
          <input type="text" class="form-control" id="fax" name="fax" [(ngModel)]="item().fax" [disabled]="isDisable()">
        </div>
      </div>
      <hr>
      <div class="row mb-3">
        <label class="col-form-label col-auto">
          ข้อมูลที่อยู่ / Address
        </label>
      </div>
      <hr>
      <div class="row mt-5 mb-1 ps-4 pe-4">
        <label for="address" class="col-xl-3  ui-require text-xl-end text-sm-start col-form-label">ที่อยู่ /
          Address</label>
        <div class="col-xl-3  mb-sm-3">
          <input type="text" class="form-control" id="address" name="address" [(ngModel)]="item().address.address"
                 required #address=ngModel [disabled]="isDisable()">
          @if (address.invalid && (address.dirty || address.touched)) {
            <custom-error *ngIf="address.hasError('required')" error="*จำเป็น"></custom-error>
          }
        </div>
        <label for="moo" class="col-xl-3 text-xl-end text-sm-start col-form-label">หมู่ที่ / Village No.</label>
        <div class="col-xl-3 mb-sm-3">
          <input type="text" class="form-control" id="moo" name="moo" [(ngModel)]="item().address.moo"
                 [disabled]="isDisable()">
        </div>
      </div>
      <div class="row mb-1 ps-4 pe-4">
        <label for="soi" class="col-xl-3 text-xl-end text-sm-start col-form-label">ซอย / Lane</label>
        <div class="col-xl-3 mb-sm-3">
          <input type="text" class="form-control" id="soi" name="soi" [(ngModel)]="item().address.soi"
                 [disabled]="isDisable()">
        </div>
        <label for="road" class="col-xl-3 text-xl-end text-sm-start col-form-label">ถนน / Road</label>
        <div class="col-xl-3 col-sm-12 mb-sm-3">
          <input type="text" class="form-control" id="road" name="road" [(ngModel)]="item().address.road"
                 [disabled]="isDisable()">
        </div>
      </div>
      <div class="row mb-1 ps-4 pe-4">
        <label for="province" class="col-xl-3 ui-require text-xl-end text-sm-start col-form-label">จังหวัด /
          Province</label>
        <div class="col-xl-3 mb-sm-3">
          <app-province-autocomplete id="province" name="province"
                                     [(ngModel)]="item().address.province" required
                                     #province=ngModel
                                     [disabled]="isDisable()"></app-province-autocomplete>
          @if (province.invalid && (province.dirty || province.touched)) {
            <custom-error *ngIf="province.hasError('required')" error="*จำเป็น"></custom-error>
          }
        </div>
        <label for="district" class="col-xl-3 ui-require text-xl-end text-sm-start col-form-label">อำเภอ/เขต /
          District</label>
        <div class="col-xl-3 mb-sm-3">
          <app-district-autocomplete id="district" name="district" [provinceId]="item().address?.province?.id"
                                     [disabled]="!!!item().address.province || isDisable()"
                                     [(ngModel)]="item().address.district" required
                                     #district=ngModel></app-district-autocomplete>
          @if (district.invalid && (district.dirty || district.touched)) {
            <custom-error *ngIf="district.hasError('required')" error="*จำเป็น"></custom-error>
          }
        </div>
      </div>
      <div class="row mb-1 ps-4 pe-4">
        <label for="subDistrict" class="col-xl-3 ui-require text-xl-end text-sm-start col-form-label">ตำบล/แขวง /
          Sub-District</label>
        <div class="col-xl-3 mb-sm-3">
          <app-subdistrict-autocomplete id="subDistrict" name="subDistrict"
                                        [districtId]="item().address?.district?.code"
                                        [disabled]="!!!item().address.district || isDisable()"
                                        [(ngModel)]="item().address.subDistrict" required
                                        #subDistrict=ngModel></app-subdistrict-autocomplete>
          @if (subDistrict.invalid && (subDistrict.dirty || subDistrict.touched)) {
            <custom-error *ngIf="subDistrict.hasError('required')" error="*จำเป็น"></custom-error>
          }
        </div>
        <label for="postcode" class="col-xl-3 ui-require text-xl-end text-sm-start col-form-label">รหัสไปรษณีย์ / Postal
          Code</label>
        <div class="col-xl-3 mb-sm-3">
          <input type="text" class="form-control" id="postcode" name="postcode" [(ngModel)]="item().address.postcode"
                 required #postcode=ngModel [disabled]="isDisable()">
          @if (postcode.invalid && (postcode.dirty || postcode.touched)) {
            <custom-error *ngIf="postcode.hasError('required')" error="*จำเป็น"></custom-error>
          }
        </div>
      </div>
      <div class="row mb-1 ps-4 pe-4">
        <label for="latitude" class="col-xl-3 text-xl-end text-sm-start col-form-label">พิกัดทางภูมิศาสตร์
          ละติจูด /
          Latitude</label>
        <div class="col-xl-3 mb-sm-3">
          <input type="text" class="form-control" id="latitude" name="latitude" [(ngModel)]="item().latitude"
                 [disabled]="isDisable()">
        </div>
        <label for="longitude" class="col-xl-3 text-xl-end text-sm-start col-form-label">ลองจิจูด /
          Longitude</label>
        <div class="col-xl-3 mb-sm-3">
          <input type="text" class="form-control" id="longitude" name="longitude" [(ngModel)]="item().longitude"
                 [disabled]="isDisable()">
        </div>
      </div>
      @if (!isDisable()) {
        <div class="row mb-5 ps-4 pe-4">
          <div class="d-xl-flex col-xl-3 d-sm-none"></div>
          <div class="col-xl-6 col-sm-12 text-xl-start text-sm-center">
            <div>
              <button type="button"
                      class="btn btn-custom btn-custom-theme me-2">
                <i class="bi bi-geo-alt-fill me-2"></i>
                ตำแหน่งปัจจุบัน
              </button>
              <button type="button" (click)="goToGoogleMap()"
                      class="btn btn-custom btn-custom-theme me-2">
                <i class="bi bi bi-map-fill me-2"></i>
                เปิดด้วย Google Map
              </button>
            </div>
          </div>
          <div class="d-xl-flex col-xl-3 d-sm-none"></div>
        </div>
      }
      <hr>
      <div class="row mb-3">
        <label class="col-form-label col-auto">
          ข้อมูลผู้ประกอบการ / Manufacturer
        </label>
      </div>
      <hr>
      <div class="row mt-5 mb-1 ps-4 pe-4">
        <label class="col-xl-3 col-sm-12 text-xl-end text-sm-start">รูปผู้ประกอบการ / Manufacturer Image</label>
        <div class="col-xl-3 col-sm-12 mb-sm-3">
          <app-file-image
            description="ไฟล์รูปภาพไม่เกิน 1MB"
            [maxFiles]="1"
            [multiple]="false"
            mode="single"
            [existingFiles]="item().manufacturerImage ? [item().manufacturerImage]:[]"
            [loading]="loading"
            (filesChanged)="onManufacturerImageChanged($event)" [disabled]="isDisable()">
          </app-file-image>
        </div>
        <label for="linkVdo" class="col-xl-3 text-xl-end text-sm-start">วีดีโอผู้ประกอบการ / Manufacturer
          Video</label>
        <div class="col-xl-3 mb-sm-3">
          <input type="text" id="linkVdo" name="linkVdo" class="form-control" [(ngModel)]="item().linkVdo"
                 [disabled]="isDisable()">
        </div>
      </div>
      <div class="row mb-1 ps-4 pe-4">
        <label for="numberOfMember" class="col-xl-3 text-xl-end text-sm-start">จำนวนสมาชิก / Member</label>
        <div class="col-xl-3 mb-sm-3">
          <input type="text" class="form-control" id="numberOfMember" name="numberOfMember"
                 [(ngModel)]="item().numberOfMembers" [disabled]="isDisable()">
        </div>
      </div>
      <div class="row mb-1 ps-4 pe-4">
        <label for="numberOfPlantation" class="col-xl-3 text-xl-end text-sm-start">จำนวนแปลง/ฟาร์ม / Plot</label>
        <div class="col-xl-3 mb-sm-3">
          <input type="text" class="form-control" id="numberOfPlantation" name="numberOfPlantation"
                 [(ngModel)]="item().numberOfPlantation" [disabled]="isDisable()">
        </div>
        <label for="totalArea" class="col-xl-3 text-xl-end text-sm-start">จำนวนพื้นที่ปลูกทั้งหมด / Total Area</label>
        <div class="col-xl-3 mb-sm-3">
          <input type="text" class="form-control" id="totalArea" name="totalArea" [(ngModel)]="item().totalArea"
                 [disabled]="isDisable()">
        </div>
      </div>
      <div class="row mb-1 ps-4 pe-4">
        <label class="col-xl-3 col-sm-12 text-xl-end text-sm-start">สถานที่ส่งจำหน่าย / Place Of Distribution</label>
        <div class="col-xl-9 col-sm-12 mb-sm-3">
          @for (place of placeOfDistribution(); let i = $index; track place.id) {
            @if (place.id != 99) {
              <input class="me-2 form-check-input" type="checkbox" id="place-{{i}}" name="placeOfDistribution{{i}}"
                     [value]="place.id" (change)="onPlaceChange($event)" [disabled]="isDisable()"
                     [checked]="checked(place.id)">
              <label for="place-{{i}}" class="me-5">{{ place.name }}</label>
            }
          }
        </div>
        <div class="d-xl-flex col-xl-3 d-sm-none"></div>
        <div class="col-xl-9 col-sm-12 mb-sm-3">
          <div class=" d-flex">
            <input [disabled]="isDisable()"
                   (click)='toggleShopEtc()'
                   [value]="99" (change)="onPlaceChange($event)"
                   class="me-2 form-check-input" type="checkbox" id="place-99" name="placeOfDistribution99"
                   [checked]="checked(99)">
            <label for="place-99" class="me-3">
              อื่น ๆ / ETC.
            </label>
            <div class="col-6">
              <input placeholder="กรุณาระบุ" type="text" class="form-control"
                     id="placeOfDistributionOther" name="placeOfDistributionOther"
                     [(ngModel)]="item().placeOfDistributionOther" [disabled]="isShopEtc || isDisable()">
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="row mb-3">
        <label class="col-form-label col-auto">
          ข้อมูลมาตรฐานระบบการผลิต / Production System Standards
        </label>
      </div>
      <hr>
      <div class="container">
        @if (!isDisable()) {
          <div class="col-12 text-end my-3">
            <a type="button" class="btn btn-custom btn-custom-theme" (click)="findCertificateFormThaiTraces()">
              เชื่อมโยงใบรับรองจาก Thai traces
            </a>
          </div>
        }
        <div class="table-responsive col-12">
          <table class="table table-striped table-borderless caption-top">
            <thead class="color-black font-weight-light">
            <tr class="table-light text-center" style="background-color: #eeeeee;">
              <th nowrap scope="col" class="">ลำดับ</th>
              <th nowrap scope="col" class="">เลขที่ใบรับรอง</th>
              <th nowrap scope="col" class="">มาตรฐาน</th>
              <th nowrap scope="col" class="">ชื่อมาตรฐาน</th>
              <th nowrap scope="col" class="">วันที่ได้รับการรับรอง</th>
              <th nowrap scope="col" class="">วันที่หมดอายุ</th>
            </tr>
            </thead>
            <tbody class="table-borderless">
              @for (item of certificates(); let i = $index; track item.id) {
                <tr>
                  <td class="text-center">{{ i + 1 }}</td>
                  <td class="text-center" nowrap>{{ item.certNo }}</td>
                  <td class="text-center" nowrap>{{ item.type }}</td>
                  <td class="text-center" nowrap>{{ item.typeDescription }}</td>
                  <td class="text-center" nowrap>{{ item.certIssuedDate|thaidatetime :{showTime: false} }}</td>
                  <td class="text-center" nowrap>{{ item.certValidUntil|thaidatetime :{showTime: false} }}</td>
                </tr>
              } @empty {
                <tr>
                  <td class="text-muted text-center" colspan="6">
                    <app-not-found></app-not-found>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
      <div class="row mb-5 mt-5">
        <div class="col-12 text-start">
          @if (!isDisable()) {
            <div class="text-center">
              <button type="button"
                      class="btn btn-custom btn-custom-theme me-2" (click)="save()">
                <i class="bi bi-floppy-fill me-2"></i>
                บันทึก
              </button>
              <button type="button"
                      class="btn btn-custom btn-light me-2" (click)="cancel()">
                <i class="bi bi-x-lg me-2"></i>
                ยกเลิก
              </button>
            </div>
          } @else {
            <div class="text-center" *hasAnyRole="['user_admin']">
              <button type="button"
                      class="btn btn-custom btn-custom-theme me-2" (click)="edit()">
                <i class="bi bi-pencil-fill me-2"></i>
                แก้ไขข้อมูล
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  </form>
</div>
