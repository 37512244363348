import {Component, inject, signal, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, NgForm} from '@angular/forms';
import {ConfirmDialogService} from '../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service';
import {ToastService} from '../../../shared/commons/toast/toast-service';
import {filter, single} from 'rxjs/operators';
import {UserRest} from '../../../service/UserRest';
import {CustomErrorComponent} from '../../../shared/custom-error.component';
import {updateFormAndValidity} from '../../../app.constants';
import {NgIf} from '@angular/common';
import {EmailRegisterDto} from '../../../../generated-model/model';

@Component({
  selector: 'app-manager-user-modal',
  standalone: true,
  imports: [
    FormsModule,
    CustomErrorComponent,
    NgIf
  ],
  templateUrl: './manager-user-modal.component.html',
  styleUrl: './manager-user-modal.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ManagerUserModalComponent {
  @ViewChild('form') public ngForm: NgForm;
  prefix = '';
  public activeModal = inject(NgbActiveModal);
   emailDto:EmailRegisterDto ={}
  confirmDialogService = inject(ConfirmDialogService)
  toastService = inject(ToastService)
  userService = inject(UserRest)

  save() {
    let valid = updateFormAndValidity(this.ngForm);
    if(!valid){
      this.toastService.showWarning("กรุณากรอกข้อมูลให้ครบถ้วน");
    }else{
      this.confirmDialogService.open({content: 'ยืนยันการบันทึกข้อมูล'}).pipe(filter(e => !!e))
        .subscribe(e =>{
          this.userService.saveInviteUser(this.emailDto).subscribe(e =>{
            this.activeModal.close()
            this.toastService.showSuccess('บันทึกข้อมูลสำเร็จ')
          })
        },error => {
          this.activeModal.close()
          this.toastService.showDangerWithMessage('เกิดข้อผิดพลาดในการบันทึกข้อมูล')
        })
    }
  }
}
