import {Component, inject} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-register-finish',
  standalone: true,
  imports: [],
  templateUrl: './register-finish.component.html',
  styleUrl: './register-finish.component.scss'
})
export class RegisterFinishComponent {
  private router = inject(Router);

  next() {
    this.router.navigate(['/operator']);
  }
}
