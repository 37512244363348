<div class="row">
  <div class="card custom-box my-3 border-0 ">
    <div class="card-body">
      <div class="row mt-3">
        <div class="text-center">
          <img src="assets/images/logo_acfs.png" class="img-card">
        </div>
      </div>
      <div class="row mt-3">
        <div class="text-center">
          <h2>
            ระบบตามสอบสินค้าเกษตรบนระบบคลาวด์
            <br>
            (QR Trace on Cloud)
          </h2>
        </div>
      </div>
      <div class="row mt-5 mb-4">
        <div class="custom-waring-box">
          <div class="alert alert-success d-flex align-items-center w-75" role="alert">
            <h5>
              กำหนดรหัสผ่านให้กับบัญชีผู้ใช้งานของท่านเรียบร้อยแล้ว
            </h5>
          </div>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="text-center">
          <button type="button"
                  class="btn btn-custom btn-custom-theme btn-custom me-2 w-75"
                  (click)="next()">
            กลับไปยังระบบ QR Trace on Cloud
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
