import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RequestUserDto} from '../../../../generated-model/model';
import {FormsModule, NgForm} from '@angular/forms';
import {JsonPipe, NgClass} from '@angular/common';
import {emailPattern, passwordPattern, updateFormAndValidity} from '../../../app.constants';
import {DigitOnlyModule} from '@uiowa/digit-only';
import {UserRegisterResource} from '../../../service/UserRegisterResource';

export type ThaiDDto = {code?: string, pid?: string, given_name?: string, family_name?: string,registerUuid?:string};

@Component({
  selector: 'app-create-user',
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    DigitOnlyModule,
    JsonPipe
  ],
  templateUrl: './create-user.component.html',
  styleUrl: './create-user.component.scss'
})
export class CreateUserComponent implements OnInit {
  @ViewChild('form') form: NgForm
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private userRegisterResource = inject(UserRegisterResource);
  thaiD : ThaiDDto = {}
  visibleConfirm: boolean = false;
  visibleReConfirm: boolean = false;
  param : RequestUserDto = {}
  confirmPassword : string
  protected readonly emailPattern = emailPattern;
  protected readonly passwordPattern = passwordPattern;

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.thaiD = {...params}
      this.param.code = this.thaiD.code
      this.param.registerUuid = this.thaiD.registerUuid
      this.param.email = this.thaiD.registerUuid
    });
  }

  viewPassword(value: string) {
    if (value == 'confirm') {
      this.visibleConfirm = !this.visibleConfirm;
    }
    if (value == 'reconfirm') {
      this.visibleReConfirm = !this.visibleReConfirm;
    }

  }

  save() {
    let valid = updateFormAndValidity(this.form)
    if (valid && this.param.password == this.confirmPassword) {
      this.userRegisterResource.register(this.param).subscribe( {
        next: e => {
          this.router.navigate(['/login']);
        }
      })
    }
  }
}
