<div class="card custom-card my-3 border-0">
  <div class="card-body mb-3">
    <div class="row mb-3">
      <label class="col-form-label col-auto">
        <i class="bi bi-search me-2"></i>
        ค้นหาข้อมูล
      </label>
    </div>
    <hr>
    <div class="row mb-1 ps-4 pe-4">
      <div class="col-xl-6 col-sm-12 mt-3">
        <div>
          <label>
            ประเภทสินค้า
          </label>
        </div>
        <div>
          <select class="form-select">
            <option [ngValue]="undefined">--- เลือกทั้งหมด ---</option>
            <option [ngValue]="undefined">ข้าว</option>
            <option [ngValue]="undefined">ไข่</option>
            <option [ngValue]="undefined">ผัก</option>
            <option [ngValue]="undefined">ผลไม้</option>
            <option [ngValue]="undefined">สมุนไพร</option>
            <option [ngValue]="undefined">ประมง</option>
            <option [ngValue]="undefined">ปศุสัตว์</option>
          </select>
        </div>
      </div>
      <div class="col-xl-6 col-sm-12 mt-3">
        <div>
          <label>
            ชื่อชนิดสินค้า
          </label>
        </div>
        <div>
          <input type="text" class="form-control">
        </div>
      </div>
    </div>
    <div class="row mb-1 ps-4 pe-4">
      <div class="col-xl-6 col-sm-12 mt-3">
        <div>
          <label>
            ชื่อเกษตรกร
          </label>
        </div>
        <div>
          <input type="text" class="form-control">
        </div>
      </div>
      <div class="col-xl-6 col-sm-12 mt-3">
        <div>
          <label>
            ชื่อชนิดย่อยสินค้า
          </label>
        </div>
        <div>
          <input type="text" class="form-control">
        </div>
      </div>
    </div>
    <div class="row mb-3 mt-5">
      <div class="col-12 text-start">
        <div class="text-center">
          <button type="button"
                  class="btn btn-custom btn-custom-theme me-2">
            <i class="bi bi-search me-2"></i>
            ค้นหา
          </button>
          <button type="button"
                  class="btn btn-custom btn-light me-2">
            <i class="bi bi-arrow-clockwise me-2"></i>
            ล้างข้อมูล
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card custom-card my-3 border-0">
  <div class="card-body mb-3">
    <div class="table-responsive">
      <table class="table table-striped table-borderless caption-top">
        <caption>
          รายการทั้งหมด {{ pageData?.totalElements | number }} รายการ
        </caption>
        <thead class="color-black font-weight-light">
        <tr class="table-light text-center" style="background-color: #eeeeee;">
          <th nowrap scope="col" class="">ลำดับ</th>
          <th nowrap scope="col" class=""></th>
          <th nowrap scope="col" class="">ประเภทสินค้า</th>
          <th nowrap scope="col" class="">ชื่อชนิดสินค้า</th>
          <th nowrap scope="col" class="">ชื่อชนิดย่อยสินค้า</th>
          <th nowrap scope="col" class="">ชื่อเกษตรกร</th>
          <th nowrap scope="col" class="">ติดต่อ</th>
        </tr>
        </thead>
        <tbody class="table-borderless">
        <tr *ngIf="!pageData?.content?.length">
          <td class="text-muted text-center" colspan="7">
            <app-not-found></app-not-found>
          </td>
        </tr>
        <tr *ngFor="let item of pageData?.content; let i= index">
          <td class="text-center"></td>
          <td class="text-center">
            <button class="btn btn-default">
              <i class="bi bi-pencil"></i>
            </button>
          </td>
          <td class="text-center"></td>
          <td class="text-center"></td>
          <td class="text-center"></td>
          <td class="text-center"></td>
          <td class="text-center"></td>
        </tr>
        </tbody>
      </table>
    </div>
    <ngb-pagination class="d-flex justify-content-center"
                    [maxSize]="5"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    [page]="pageData?.number + 1"
                    (pageChange)="search($event)"
                    [pageSize]="pageData?.size"
                    [collectionSize]="pageData?.totalElements"></ngb-pagination>
  </div>
</div>
