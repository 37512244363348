<div class="modal-header pb-0 border-0">
  <button
    type="button"
    class="btn-close"
    aria-describedby="modal-title"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body pt-0 text-center">
  <div class="m-auto">
    <!--    <img src="assets/icons/cross.png" alt="" height="60">-->
    <i class="bi bi-x-circle text-danger fs-1"></i>
  </div>
  <p class="text-danger py-3 fs-5">เกิดข้อผิดพลาด</p>
</div>
<!--<div class="modal-footer">-->
<!--  <button type="button" class="btn btn-danger " (click)="this.activeModal.close('Ok click')">ปิด</button>-->
<!--</div>-->
