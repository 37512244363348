import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {CommonModule} from '@angular/common';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {ConfirmDialogService} from './shared/commons/dialog/confirm-dialog/confirm-dialog.service';
import {ResourceRole, SsoUser} from './app.constants';
import {ManufacturerRest} from './service/ManufacturerRest';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'QRTraces';
  public router: Router = inject(Router);
  private oidcSecurityService = inject(OidcSecurityService)
  private confirmDialogService = inject(ConfirmDialogService)
  private activatedRoute = inject(ActivatedRoute);
  manufacturerRest = inject(ManufacturerRest)
  isLoginConfirmDialogOpen = false

  ngOnInit(): void {
    this.oidcSecurityService.checkAuth().subscribe(e => {
      //Should go any other place let ng oidc navigate for you
      if (e.isAuthenticated) {
        const userData = e.userData as SsoUser
        console.log(userData)
        if (userData?.resource_access?.['qrtraces']) {
          if (!userData?.pid) {
            const urlParams = new URLSearchParams(window.location.search);
            const refresh_session = urlParams.get('refresh_session')
            if (refresh_session) {
              this.oidcSecurityService.forceRefreshSession().subscribe(e => {
                console.log("refresh token successful", e)
                this.navigateByRole((e.userData as SsoUser)?.resource_access?.['qrtraces'])
              })
            } else {
              this.router.navigate(['register', 'verify-thaid'])
            }
          } else {
            this.navigateByRole(userData?.resource_access?.['qrtraces'])
          }
        } else {
          this.router.navigate(['403'])
        }
      }else{
        console.log(window.location.pathname)
        if(!window.location.pathname.startsWith("/register") && !window.location.pathname.startsWith("/p") && !window.location.pathname.startsWith("/welcome")){
          this.router.navigate(['login'])
        }
      }
    });

    this.oidcSecurityService.checkSessionChanged$.subscribe(e => {
      console.log('checkSessionChanged$', e)
      if (e && !this.isLoginConfirmDialogOpen) {
        this.isLoginConfirmDialogOpen = true
        this.confirmDialogService.open({content: 'session หมดอายุ ต้องการล็อกอินอีกครั้บกด ยืนยัน?'}).subscribe(e => {
          if (e) {
            this.oidcSecurityService.logoff()
            this.oidcSecurityService.authorize()
          }
        })
      }
    })
  }

  private navigateByRole(userData: ResourceRole | undefined) {
    if ("/" === window.location.pathname) {
      if (userData.roles.includes('user')) {
        this.manufacturerRest.findBySsoUserId().subscribe(e=>{
          console.log("login as ",e?.businessType)
          if(!e){
            this.router.navigate(['manufacturer'])
          }else{
            this.router.navigate(['welcome'])
          }
        })
      }
    }
    //this.router.navigate(['form'])
  }
}
