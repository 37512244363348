import {Component} from '@angular/core';
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {NotFoundComponent} from "../../shared/not-found/not-found.component";
import {Page} from '../../../generated-model/model';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [
    DecimalPipe,
    NgForOf,
    NgIf,
    NgbPagination,
    NotFoundComponent
  ],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent {
  pageData: Page<any> = {size: 10};

  constructor(private modalService: NgbModal) {
  }

  search(page: number = 1) {
  }
}
