<div class="card custom-card my-3 border-0">
  <div class="card-body">
    <div class="row mb-3">
      <label class="col-form-label col-auto">
        ข้อมูลทั่วไป
      </label>
    </div>
    <hr>
    <div class="row mb-3 mt-5">
      <div class="col-3 text-end">
        <label>
          ชื่อ / Name
        </label>
      </div>
      <div class="col-3 text-start">
        <label>
          ทดสอบ
        </label>
      </div>
      <div class="col-3 text-end">
        <label>
          นามสกุล / Surname
        </label>
      </div>
      <div class="col-3 text-start">
        <label>
          ทดสอบ
        </label>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3 text-end">
        <label>
          เบอร์ติดต่อ / Telephone
        </label>
      </div>
      <div class="col-3 text-start">
        <label>
          099-999-9999
        </label>
      </div>
      <div class="col-3 text-end">
        <label>อีเมล / e-Mail</label>
      </div>
      <div class="col-3 text-start">
        <label>
          test&#64;gmail.com
        </label>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3 text-end">
        <label>
          ชื่อผู้ใช้งาน / Username
        </label>
      </div>
      <div class="col-3 text-start">
        <label>
          somchai_jaidee
        </label>
      </div>
      <div class="col-3 text-end">
        <label class="ui-require">
          เลือกประเภทผู้ใช้งาน /<br> Business Type
        </label>
      </div>
      <div class="col-3">
        <select class="form-select">
          <option [ngValue]="undefined">--- กรุณาเลือก ---</option>
          <option [ngValue]="undefined">บริษัท</option>
          <option [ngValue]="undefined">เกษตรกร</option>
          <option [ngValue]="undefined">กลุ่มเกษตรกร</option>
          <option [ngValue]="undefined">วิสาหกิจชุมชน</option>
          <option [ngValue]="undefined">กลุ่มสหกรณ์</option>
        </select>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3 text-end">
        <label class="ui-require">
          เลือกประเภทผู้ประกอบการ /<br> Select Juristic Type
        </label>
      </div>
      <div class="col-3 text-start">
        <select class="form-select">
          <option [ngValue]="undefined">--- กรุณาเลือก ---</option>
          <option [ngValue]="undefined">บุคคลธรรมดา</option>
          <option [ngValue]="undefined">นิติบุคคล</option>
        </select>
      </div>
      <div class="col-3 text-end">
        <label class="ui-require">
          รหัสผู้ประกอบการ /<br> Operator No.
        </label>
      </div>
      <div class="col-3">
        <input class="me-2 form-check-input"
               name="number-company"
               [ngModel]="haveCode"
               [value]="true"
               type="radio"
               (ngModelChange)="changeValue($event)"/>
        <label class="me-5">มีรหัส</label>
        <input class="me-2 form-check-input"
               name="number-company"
               [ngModel]="haveCode"
               [value]="false"
               type="radio"
               (ngModelChange)="changeValue($event)"/>
        <label class="me-5">ไม่มีรหัส</label>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3 text-end">
        <label class="ui-require">
          เลขทะเบียนนิติบุคคล /<br> Juristic ID.
        </label>
      </div>
      <div class="col-3 text-start">
        <input type="text" class="form-control">
      </div>
      <div class="col-3"></div>
      <div class="col-3 text-start">
        <input type="text" id="companyCode" name="companyCode" [disabled]="!haveCode"
               placeholder="กรุณาระบุ ( กรณีมีรหัส )" class="form-control">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3 text-end">
        <label class="ui-require">
          ชื่อกิจการ/บริษัท /<br> Business/Company Name
        </label>
      </div>
      <div class="col-9 text-start">
        <input type="text" class="form-control">
      </div>
      <div class="col-6">
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-3 text-end">
        <label>
          หมายเลข GLN (ถ้ามี) /<br> Global Location Number
        </label>
      </div>
      <div class="col-3 text-start">
        <input type="text" class="form-control">
      </div>
      <div class="col-3 text-end">
        <label>
          URL (ถ้ามี)
        </label>
      </div>
      <div class="col-3">
        <input type="text" class="form-control">
      </div>
    </div>
    <hr>
    <div class="row mb-3">
      <label class="col-form-label col-auto">
        ข้อมูลที่อยู่ / Address
      </label>
    </div>
    <hr>
    <div class="row mb-3 mt-5">
      <div class="col-3 ui-require text-end">
        <label>
          ที่อยู่ / Address
        </label>
      </div>
      <div class="col-9 text-start">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3 text-end">
        <label>
          หมู่ที่ / Village No.
        </label>
      </div>
      <div class="col-3 text-start">
        <input type="text" class="form-control">
      </div>
      <div class="col-3 text-end">
        <label>
          ซอย / Street
        </label>
      </div>
      <div class="col-3 text-start">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3 text-end">
        <label>
          ถนน / Road
        </label>
      </div>
      <div class="col-3 text-start">
        <input type="text" class="form-control">
      </div>
      <div class="col-3 ui-require text-end">
        <label>
          จังหวัด / Province
        </label>
      </div>
      <div class="col-3 text-start">
        <select class="form-select">
          <option [ngValue]="undefined">--- กรุณาเลือก ---</option>
        </select>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3 ui-require text-end">
        <label>
          อำเภอ/เขต / District
        </label>
      </div>
      <div class="col-3 text-start">
        <select class="form-select">
          <option [ngValue]="undefined">--- กรุณาเลือก ---</option>
        </select>
      </div>
      <div class="col-3 ui-require text-end">
        <label>
          ตำบล/แขวง / Sub-District
        </label>
      </div>
      <div class="col-3 text-start">
        <select class="form-select">
          <option [ngValue]="undefined">--- กรุณาเลือก ---</option>
        </select>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3 ui-require text-end">
        <label>
          รหัสไปษณีย์ / Postal Code
        </label>
      </div>
      <div class="col-3 text-start">
        <input type="text" class="form-control">
      </div>
      <div class="col-3 text-end">
        <label>
          แฟกซ์ / Fax
        </label>
      </div>
      <div class="col-3 text-start">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3 text-end">
        <label>
          พิกัดทางภูมิศาสตร์ ละติจูด / Latitude
        </label>
      </div>
      <div class="col-3 text-start">
        <input type="text" class="form-control">
      </div>
      <div class="col-3 text-end">
        <label>
          ลองจิจูด / Longitude
        </label>
      </div>
      <div class="col-3 text-start">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-3"></div>
      <div class="col-6 text-start">
        <div class="text-start">
          <button type="button"
                  class="btn btn-custom btn-custom-theme me-2">
            <i class="bi bi-geo-alt-fill me-2"></i>
            ตำแหน่งปัจจุบัน
          </button>
          <button type="button" (click)="goToGoogleMap()"
                  class="btn btn-custom btn-custom-theme me-2">
            <i class="bi bi bi-map-fill me-2"></i>
            เปิดด้วย Google Map
          </button>
        </div>
      </div>
      <div class="col-3"></div>
    </div>
    <hr>
    <div class="row mb-3">
      <label class="col-form-label col-auto">
        ข้อมูลผู้ประกอบการ / Entrepreneur
      </label>
    </div>
    <hr>
    <div class="row mb-3 mt-5">
      <div class="col-3 text-end">
        <label>
          รูปผู้ประกอบการ /<br> Entrepreneur Image
        </label>
      </div>
      <div class="col-3 text-center">
        <div ng2FileDrop
             [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
             (fileOver)="fileOverBase($event)"
             [uploader]="uploader"
             class="well my-drop-zone text-muted">
          <br>
          <i class="bi bi-upload" style="font-size: 7.5rem"></i>
          <br>
          Drag file here or
          <button type="button" (click)="fileInput.click()" class="btn btn-custom btn-light">Browse
          </button>
          <input type="file" #fileInput ng2FileSelect [uploader]="uploader" style="display: none"/>
          <div *ngIf="uploader?.queue?.length"
               class="text-primary mt-2 small">{{ uploader?.queue[0]?.file?.name }}
          </div>
        </div>
      </div>
      <div class="col-3 text-end">
        <label>
          วีดีโอผู้ประกอบการ /<br> Entrepreneur Video
        </label>
      </div>
      <div class="col-3 text-start">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3 text-end">
        <label>
          จำนวนสมาชิก / Membership
        </label>
      </div>
      <div class="col-3 text-start">
        <input type="text" class="form-control">
      </div>
      <div class="col-6"></div>
    </div>
    <div class="row mb-3">
      <div class="col-3 text-end">
        <label>
          จำนวนแปลง/ฟาร์ม /<br> Number of Plots/Farm
        </label>
      </div>
      <div class="col-3 text-start">
        <input type="text" class="form-control">
      </div>
      <div class="col-3 text-end">
        <label>
          จำนวนพื้นที่ปลูกทั้งหมด /<br> Total Planting Area
        </label>
      </div>
      <div class="col-3 text-start">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3 text-end">
        <label>
          สถานที่ส่งจำหน่าย /<br> Place Of Distribution
        </label>
      </div>
      <div class="col-9 text-start">
        <input class="me-2 form-check-input" type="checkbox"/><label class="me-5">Tops supermarket</label>
        <input class="me-2 form-check-input" type="checkbox"/><label class="me-5">MAKRO</label>
        <input class="me-2 form-check-input" type="checkbox"/><label class="me-5">Big C</label>
        <input class="me-2 form-check-input" type="checkbox"/><label class="me-5">Lotus's</label>
        <input class="me-2 form-check-input" type="checkbox"/><label class="me-5">GO Wholesale</label>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3 text-end"></div>
      <div class="col-9 text-start">
        <div class="input-group">
          <input [ngModel]="isShopEtc"
                 (click)='toggleShopEtc()'
                 class="me-2 form-check-input" type="checkbox"/>
          <label class="me-3">
            อื่น ๆ / ETC.
          </label>
          <input placeholder="กรุณาระบุ" [disabled]="!isShopEtc" type="text" class="form-control">
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3 text-end">
        <label>
          มาตรฐานระบบการผลิต /<br> Production System Standards
        </label>
      </div>
      <div class="col-3"></div>
      <div class="col-3 text-start">
        <label>
          เลขที่ใบรับรอง /<br> Certificate Number
        </label>
      </div>
      <div class="col-3 text-start">
        <label>
          วันที่ได้รับใบรับรอง /<br> Date Of Receipt Of Certificate
        </label>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3"></div>
      <div class="col-3">
        <input class="me-2 form-check-input" type="checkbox"/><label>Primary GMP</label>
      </div>
      <div class="col-3 text-start">
        <input type="text" class="form-control">
      </div>
      <div class="col-3 text-start">
        <gt-ng-datepicker></gt-ng-datepicker>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3"></div>
      <div class="col-3">
        <input class="me-2 form-check-input" type="checkbox"/><label>GMP</label>
      </div>
      <div class="col-3 text-start">
        <input type="text" class="form-control">
      </div>
      <div class="col-3 text-start">
        <gt-ng-datepicker></gt-ng-datepicker>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3"></div>
      <div class="col-3">
        <input class="me-2 form-check-input" type="checkbox"/><label>HACCP</label>
      </div>
      <div class="col-3 text-start">
        <input type="text" class="form-control">
      </div>
      <div class="col-3 text-start">
        <gt-ng-datepicker></gt-ng-datepicker>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3"></div>
      <div class="col-3">
        <input class="me-2 form-check-input" type="checkbox"/><label>อย. / FDA</label>
      </div>
      <div class="col-3 text-start">
        <input type="text" class="form-control">
      </div>
      <div class="col-3 text-start">
        <gt-ng-datepicker></gt-ng-datepicker>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3"></div>
      <div class="col-3">
        <div class="input-group">
          <input [ngModel]="isStdEtc"
                 (click)='toggleStdEtc()'
                 class="me-2 form-check-input"
                 type="checkbox"/>
          <label class="me-3">
            อื่นๆ / ETC.
          </label>
          <input type="text" placeholder="กรุณาระบุ" [disabled]="!isStdEtc" class="form-control">
        </div>
      </div>
      <div class="col-3 text-start">
        <input type="text" [disabled]="!isStdEtc" class="form-control">
      </div>
      <div class="col-3 text-start">
        <gt-ng-datepicker [disabled]="!isStdEtc"></gt-ng-datepicker>
      </div>
    </div>
    <div class="row mb-5 mt-5">
      <div class="col-12 text-start">
        <div class="text-center">
          <button type="button"
                  class="btn btn-custom btn-custom-theme me-2">
            <i class="bi bi-floppy-fill me-2"></i>
            บันทึก
          </button>
          <button type="button"
                  class="btn btn-custom btn-light me-2">
            <i class="bi bi-x-lg me-2"></i>
            ยกเลิก
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
