import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PlaceOfDistribution} from '../../generated-model/model';
import {environment} from '../../environments/environment';

@Injectable({providedIn: "root"})
export class PlaceOfDistributionRest{
  http = inject(HttpClient)

  getAll(){
    return this.http.get<PlaceOfDistribution[]>(`${environment.serverUrl}/api/place-of-distribution`)
  }
}
