<div class="card custom-card my-3 border-0">
  <div class="card-body mb-3">
    <div class="row mb-3">
      <label class="col-form-label col-auto">
        <i class="bi bi-search me-2"></i>
        ค้นหาข้อมูล
      </label>
    </div>
    <hr>
    <div class="row mb-3 mt-4 ps-4 pe-4">
      <div class="col-4">
        <label>ชื่อผู้ใช้งาน / Username</label>
      </div>
      <div class="col-4">
        <label>ชื่อ - นามสกุล / FullName</label>
      </div>
      <div class="col-4">
        <label>สถานะ / Status</label>
      </div>
    </div>
    <div class="row mb-3 ps-4 pe-4">
      <div class="col-4">
        <input type="text" class="form-control">
      </div>
      <div class="col-4">
        <input type="text" class="form-control">
      </div>
      <div class="col-4">
        <select class="form-select">
          <option [ngValue]="undefined">--- เลือกทั้งหมด ---</option>
          <option [ngValue]="undefined">ใช้งาน (Active)</option>
          <option [ngValue]="undefined">ไม่ใช้งาน (InActive)</option>
          <option [ngValue]="undefined">รอยืนยันตัวตน</option>
        </select>
      </div>
    </div>
    <div class="row mb-3 mt-5">
      <div class="col-12 text-start">
        <div class="text-center">
          <button type="button"
                  class="btn btn-custom btn-custom-theme me-2">
            <i class="bi bi-search me-2"></i>
            ค้นหา
          </button>
          <button type="button"
                  class="btn btn-custom btn-light me-2">
            <i class="bi bi-arrow-clockwise me-2"></i>
            ล้างข้อมูล
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card custom-card my-3 border-0">
  <div class="card-body mb-3">
    <div class="table-responsive">
      <table class="table table-striped table-borderless caption-top">
        <caption>
          รายการทั้งหมด {{ pageData?.totalElements | number }} รายการ
          <button class="btn btn-custom btn-custom-theme float-end" (click)="openModel('เพิ่ม')">
            <i class="bi bi-plus-lg me-1"></i>
            เพิ่มผู้ใช้งาน (New)
          </button>
        </caption>
        <thead class="color-black font-weight-light">
        <tr class="table-light text-center" style="background-color: #eeeeee;">
          <th nowrap scope="col" class="">ลำดับ</th>
          <th nowrap scope="col" class=""></th>
          <th nowrap scope="col" class="">ชื่อผู้ใช้งาน / Username</th>
          <th nowrap scope="col" class="">ชื่อ - นามสกุล / FullName</th>
          <th nowrap scope="col" class="">เบอร์ติดต่อ / Telephone Number</th>
          <th nowrap scope="col" class="">อีเมล / Email</th>
          <th nowrap scope="col" class="">สถานะ / Status</th>
        </tr>
        </thead>
        <tbody class="table-borderless">
        <tr *ngIf="!pageData?.content?.length">
          <td class="text-muted text-center" colspan="7">
            <app-not-found></app-not-found>
          </td>
        </tr>
        <tr *ngFor="let item of pageData?.content; let i= index">
          <td class="text-center"></td>
          <td class="text-center">
            <button class="btn btn-default">
              <i class="bi bi-pencil"></i>
            </button>
          </td>
          <td class="text-center"></td>
          <td class="text-center"></td>
          <td class="text-center"></td>
          <td class="text-center"></td>
          <td class="text-center"></td>
        </tr>
        </tbody>
      </table>
    </div>
    <ngb-pagination class="d-flex justify-content-center"
                    [maxSize]="5"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    [page]="pageData?.number + 1"
                    (pageChange)="search($event)"
                    [pageSize]="pageData?.size"
                    [collectionSize]="pageData?.totalElements"></ngb-pagination>
  </div>
</div>

