<ng-template ngFor let-breadcrumb [ngForOf]="breadcrumbs | async" let-last=last let-first=first>
  @if (first && last) {
    @if (breadcrumb.label.srcIcon != null) {
      <img class="custom-icon-breadcrumb" src="{{ breadcrumb.label.srcIcon }}" alt="">
    }
    <span class="text-muted my-auto custom-text-title">{{ breadcrumb.label.title }}</span>
  } @else {
    @if (first) {
      @if (breadcrumb.label.srcIcon != null) {
        <img class="custom-icon-breadcrumb" src="{{ breadcrumb.label.srcIcon }}" alt="">
      }
      <span class="text-muted my-auto custom-text-title">{{ breadcrumb.label.title }}</span>
    } @else {
      @if (breadcrumb.label.srcIcon != null) {
        <img class="custom-icon-breadcrumb" src="{{ breadcrumb.label.srcIcon }}" alt="">
      }
      <i class="bi bi-chevron-right me-3 ms-3"></i><span
        class="text-muted my-auto custom-text-title">{{ breadcrumb.label.title }}</span>
    }
  }
</ng-template>
