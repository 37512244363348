<div class="row">
  <div class="card custom-box my-3 border-0 ">
    <div class="card-body">
      <div class="row mt-3">
        <div class="text-center">
          <img src="assets/images/logo_acfs.png" class="img-card">
        </div>
      </div>
      <div class="row mt-3">
        <div class="text-center">
          <h2>
            ระบบตามสอบสินค้าเกษตรบนระบบคลาวด์
            <br>
            (QR Trace on Cloud)
          </h2>
        </div>
      </div>
      <div class="row mt-5 mb-4">
        <div class="col-5 text-end">
          <label class="ui-require">ชื่อ / Name</label>
        </div>
        <div class="col-7 text-start">
          <label>ขยัน</label>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-5 text-end">
          <label class="ui-require">นามสกุล / Surname</label>
        </div>
        <div class="col-7 text-start">
          <label>ทดสอบ</label>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-5 text-end">
          <label class="ui-require">เบอร์ติดต่อ / Telephone</label>
        </div>
        <div class="col-7 text-start">
          <input type="text" class="form-control w-50">
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-5 text-end">
          <label class="ui-require">e-Mail</label>
        </div>
        <div class="col-7 text-start">
          <input type="text" class="form-control w-50">
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-5 text-end">
          <label class="ui-require">ชื่อผู้ใช้งาน / Username</label>
        </div>
        <div class="col-7 text-start">
          <input type="text" class="form-control w-50">
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-5">
        </div>
        <div class="col-7 text-start">
          <button type="button"
                  class="btn btn-custom btn-custom-theme btn-thaid me-2 w-50"
                  (click)="next()">
            ยืนยันการลงทะเบียน
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
