import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { ManufacturerRest } from '../../service/ManufacturerRest';
import { Observable, map, of } from 'rxjs';
import {ManufacturerDto} from '../../../generated-model/model';

export const hasManufacturerGuard: CanActivateFn = (route, state): Observable<boolean | UrlTree> => {
  const manufacturerRest = inject(ManufacturerRest);
  const router = inject(Router);

  return manufacturerRest.findBySsoUserId().pipe(
    map((manufacturer: ManufacturerDto | null) => {
      if (!manufacturer) {
        return router.createUrlTree(['/welcome']);
      }
      return true;
    })
  );
};
