import {Routes} from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {RegisterComponent} from './pages/register/register.component';
import {RegisterThaidComponent} from './pages/register/register-thaid/register-thaid.component';
import {ThaidComponent} from './pages/register/thaid/thaid.component';
import {CreateUserComponent} from './pages/register/create-user/create-user.component';
import {EmailVerificationComponent} from './pages/register/email-verification/email-verification.component';
import {ConfirmPasswordComponent} from './pages/register/confirm-password/confirm-password.component';
import {RegisterFinishComponent} from './pages/register/register-finish/register-finish.component';
import {RegisterPolicyComponent} from './pages/register/register-policy/register-policy.component';
import {LayoutComponent} from './pages/layout/layout.component';
import {OperatorComponent} from './pages/operator/operator.component';
import {ManagerUserComponent} from './pages/manager-user/manager-user.component';
import {ManagerQrcodeComponent} from './pages/manager-qrcode/manager-qrcode.component';
import {SearchComponent} from './pages/search/search.component';
import {FormComponent} from './pages/form/form.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent,
    children: [
      {
        path: '',
        component: RegisterPolicyComponent
      },
      {
        path: 'start-thaid',
        component: RegisterThaidComponent
      },
      {
        path: 'thaid',
        component: ThaidComponent
      },
      {
        path: 'create',
        component: CreateUserComponent
      },
      {
        path: 'verify',
        component: EmailVerificationComponent
      },
      {
        path: 'confirm',
        component: ConfirmPasswordComponent
      },
      {
        path: 'finish',
        component: RegisterFinishComponent
      },
    ]
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'form',
        component: FormComponent,
        loadChildren: () => import('./pages/form/form.module').then(m => m.FormModule)
      },
      {
        path: 'operator',
        component: OperatorComponent,
        data: {title: 'ข้อมูลผู้ประกอบการ', srcIcon: '/assets/icons/user.svg'}
      },
      {
        path: 'manager',
        component: ManagerUserComponent,
        data: {title: 'ข้อมูลผู้ใช้งานกลุ่ม/บริษัท'}
      },
      {
        path: 'master',
        loadChildren: () => import('./pages/master/master.module').then(m => m.MasterModule)
      },
      {
        path: 'qrcode',
        component: ManagerQrcodeComponent,
        data: {title: 'การจัดการ QR Code'}
      },
      {
        path: 'report',
        loadChildren: () => import('./pages/report/report.module').then(m => m.ReportModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: 'search',
        component: SearchComponent,
        data: {title: 'ค้นหาวัตถุดิบสินค้าเกษตร'}
      },
    ]
  }
];
