<div class="container-fluid">
  <div class="row">
    <!--    @if (toggleSidebar()) {-->
    <div class="col-auto d-xl-block d-none p-0">
      <div id="sidebar">
        <div id="sidebar-menu" class="offcanvas-md offcanvas-end" tabindex="-1" aria-labelledby="sidebar-menu-label">
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="sidebar-menu-label">
              ระบบตามสอบสินค้าเกษตรบนระบบคลาวด์ (QR Trace on Cloud)</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebar-menu"
                    aria-label="Close"></button>
          </div>
          <div class=" d-md-flex flex-column custom-side-bar">
            <ul class="nav flex-column">
              <li class="nav-item d-flex justify-content-center p-2 text-centerc">
                <a class="navbar-brand d-none d-md-flex">
                  <img src="/assets/images/logo_acfs.png" alt="Logo" class="">
                  <h6 class="text-logo my-auto">
                    ระบบตามสอบสินค้าเกษตร
                    บนระบบคลาวด์<br>
                    (QR Trace on Cloud)
                  </h6>
                </a>
              </li>
            </ul>
            <span class="custom-hr"><hr></span>
            <div id="sidebar-content">
              <ul class="nav flex-column mt-1">
                <li class="nav-item">
                  <a routerLink="/operator" routerLinkActive="active"
                     class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2">
                    <i class="bi bi-person-fill"></i>
                    ข้อมูลผู้ประกอบการ
                  </a>
                </li>
              </ul>
              <ul class="nav flex-column">
                <li class="nav-item">
                  <a routerLink="/manager" routerLinkActive="active"
                     class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
                    <i class="bi bi-people-fill"></i>
                    เพิ่มผู้ใช้งานในกลุ่ม/บริษัท
                  </a>
                </li>
                <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#master-data-collapse">
                  <a class="nav-link nav-link-collapse">
                    <i class="bi bi-database"></i>
                    <span>ข้อมูลหลัก (Master Data)</span>
                    <i class="rtl:rotate-180 right-icon"
                       x-bind:style="selected.includes('menu_style') ? 'transform:  rotate(90deg)' : ''">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24"
                           stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                      </svg>
                    </i>
                  </a>
                </li>
                <div class="collapse" id="master-data-collapse">
                  <li class="nav-item">
                    <a routerLink="/master/general" routerLinkActive="active"
                       class="nav-link btn d-flex align-items-center align-middle border-0 gap-2">
                      ข้อมูลทะเบียนทั่วไป
                    </a>
                  </li>
                  <li class="nav-item">
                    <a routerLink="/master/production" routerLinkActive="active"
                       class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
                      ข้อมูลทะเบียนการผลิต
                    </a>
                  </li>
                </div>
                <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#form-collapse">
                  <a routerLink="/form" routerLinkActive="active" class="nav-link nav-link-collapse">
                    <i class="bi bi-file-earmark-text-fill"></i>
                    <span>แบบฟอร์ม</span>
                    <i class="rtl:rotate-180 right-icon"
                       x-bind:style="selected.includes('menu_style') ? 'transform:  rotate(90deg)' : ''">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24"
                           stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                      </svg>
                    </i>
                  </a>
                </li>
                <div class="collapse" id="form-collapse">
                  <li class="nav-item">
                    <a class="nav-link btn d-flex align-items-center align-middle border-0 gap-2">
                      ผัก/ผลไม้
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
                      ข้าว
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
                      ไข่
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
                      ประมง
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
                      ปศุสัตว์
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
                      อาหารแปรรูป
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
                      สมุนไพร
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
                      ผลิตภัณฑ์แปรรูปอื่น (ที่ไม่ใช่อาหาร)
                    </a>
                  </li>
                </div>
                <li class="nav-item">
                  <a routerLink="/qrcode" routerLinkActive="active"
                     class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
                    <i class="bi bi-qr-code"></i>
                    การจัดการ QR Code
                  </a>
                </li>
                <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#report-collapse">
                  <a class="nav-link nav-link-collapse">
                    <i class="bi bi-graph-up"></i>
                    <span>รายงาน</span>
                    <i class="rtl:rotate-180 right-icon"
                       x-bind:style="selected.includes('menu_style') ? 'transform:  rotate(90deg)' : ''">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24"
                           stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                      </svg>
                    </i>
                  </a>
                </li>
                <div class="collapse" id="report-collapse">
                  <li class="nav-item">
                    <a routerLink="/report/dashboard" routerLinkActive="active"
                       class="nav-link btn d-flex align-items-center align-middle border-0 gap-2">
                      Dashboard
                    </a>
                  </li>
                  <li class="nav-item">
                    <a routerLink="/report/all" routerLinkActive="active"
                       class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
                      รายงานทั้งหมด
                    </a>
                  </li>
                </div>
                <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#manage-data-collapse">
                  <a class="nav-link nav-link-collapse">
                    <i class="bi bi-gear"></i>
                    <span>การจัดการข้อมูล Admin</span>
                    <i class="rtl:rotate-180 right-icon"
                       x-bind:style="selected.includes('menu_style') ? 'transform:  rotate(90deg)' : ''">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24"
                           stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                      </svg>
                    </i>
                  </a>
                </li>
                <div class="collapse" id="manage-data-collapse">
                  <li class="nav-item">
                    <a routerLink="/admin/user" routerLinkActive="active"
                       class="nav-link btn d-flex align-items-center align-middle border-0 gap-2">
                      จัดการผู้ใช้งานระบบ
                    </a>
                  </li>
                  <li class="nav-item">
                    <a routerLink="/admin/qrcode" routerLinkActive="active"
                       class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
                      จัดการการหมดอายุ QR Code
                    </a>
                  </li>
                  <li class="nav-item">
                    <a routerLink="/admin/register-group" routerLinkActive="active"
                       class="nav-link btn d-flex align-items-center align-middle border-0 gap-2">
                      จัดการข้อมูลทะเบียนกลุ่ม
                    </a>
                  </li>
                </div>
                <li class="nav-item mb-5">
                  <a routerLink="/search" routerLinkActive="active"
                     class="nav-link btn d-flex align-items-center align-middle  border-0 gap-2">
                    <i class="bi bi-pc-display-horizontal"></i>
                    ค้นหา
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col overflow-y-auto p-0">
      <header id="header">
        <nav class="navbar navbar-expand-lg">
          <div class="container-fluid">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
              <i class="bi bi-list custom-icon-list"></i> <!-- ตัวน่อ --->
            </button>
            <button class="navbar-toggler d-lg-block border-0" type="button">
              <i class="bi bi-list custom-icon-list"></i>
            </button>
            <div class="collapse navbar-collapse mx-3" id="navbarSupportedContent" style="flex-direction:row-reverse">
              <div class="dropdown d-flex m-2">
                <div class="text-end d-block text-decoration-none me-3 my-auto fs-5 fw-bolder">
                  ขยัน ทดสอบ
                </div>
                <div class="flex-shrink-0 dropdown">
                  <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="bi-person-circle fs-1 custom-icon-user"></i>
                  </a>
                  <ul class="dropdown-menu text-small" style="left :-95px">
                    <li><a class="dropdown-item"><i class="bi bi-person me-2"></i>โปรไฟล์</a></li>
                    <li><a class="dropdown-item"><i class="bi bi-box-arrow-in-right me-2"></i>ออกจากระบบ</a>
                    </li>
                  </ul>
                </div>
              </div>

              <ul class="navbar-nav flex-row d-md-none">
                <li class="nav-item text-nowrap">
                  <button class="nav-link px-3"
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#sidebar-menu" aria-controls="sidebar-menu" aria-expanded="false"
                          aria-label="Toggle navigation">
                    <span class="bi bi-list"></span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <div id="content">
        <div class="ps-4 pt-4 pe-4 pb-0">
          <app-loading></app-loading>
          <app-header></app-header>
          <div>
            <router-outlet/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

