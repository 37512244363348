import {Component, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';

@Component({
  selector: 'app-manager-user-modal',
  standalone: true,
  imports: [
    FormsModule
  ],
  templateUrl: './manager-user-modal.component.html',
  styleUrl: './manager-user-modal.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ManagerUserModalComponent {
  prefix = '';

  constructor(public activeModal: NgbActiveModal) {
  }

  save() {
  }
}
