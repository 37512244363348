import { Component } from '@angular/core';
import {RouterLink, RouterLinkActive} from '@angular/router';

@Component({
  selector: 'app-thaid',
  standalone: true,
  imports: [
    RouterLinkActive,
    RouterLink
  ],
  templateUrl: './thaid.component.html',
  styleUrl: './thaid.component.scss'
})
export class ThaidComponent {
 // Mock ไว้ เฉยๆ
}
