import {Directive, inject, input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Subscription} from 'rxjs';
import {ResourceAccess, ROLE} from '../app.constants';

@Directive({
  selector: '[hasAnyRole]',
  standalone: true
})
export class HasAnyRoleDirective implements OnInit, OnDestroy {
  hasAnyRole = input.required<ROLE[]>();
  andNotHaveRole = input<ROLE>();
  private templateRef = inject(TemplateRef<any>);
  private viewContainer = inject(ViewContainerRef);
  private subscription?: Subscription;
  private oidcSecurityService = inject(OidcSecurityService);

  constructor() {
  }

  ngOnInit(): void {
    this.subscription = this.oidcSecurityService
      .getPayloadFromAccessToken(false)
      .subscribe((payload) => {
        this.updateView(payload);
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private updateView(payload: any): void {
    this.viewContainer.clear();
    if (payload) {
      const resourceAccess = payload as ResourceAccess;
      if (resourceAccess?.resource_access) {
        const qrtracesAccess = resourceAccess?.resource_access?.['qrtraces'];
        if (qrtracesAccess && qrtracesAccess.roles) {
          const hasRequiredRole =
            this.hasAnyRole().length === 0 ||
            this.hasAnyRole().some((role) => qrtracesAccess.roles.includes(role));
          const hasForbiddenRole = qrtracesAccess.roles.includes(this.andNotHaveRole())
          if (hasRequiredRole && !hasForbiddenRole) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          }
        }
      }
    }
  }
}
