<div class="row">
  <div class="card custom-box my-3 border-0 ">
    <div class="card-body">
      <div class="row mt-3">
        <div class="text-center">
          <h3>
            ผู้ใช้งาน {{user?.preferred_username}} ({{user?.name}}) นี้ยังไม่ได้ผูก ThaiD <br/>
            กรุณายืนยันตัวตนด้วยแอปพลิเคชั่น ThaID
          </h3>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="text-center">
          <a [href]="setPidUrl"
             class="btn btn-custom btn-custom-theme btn-thaid me-2 w-75" >
            คลิก เพื่อยืนยันตัวตนด้วยแอปพลิเคชั่น ThaID
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
