import {Component, inject} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {SsoUser} from '../../../app.constants';
import {Router} from '@angular/router';

@Component({
  selector: 'app-verify-thaid',
  standalone: true,
  imports: [],
  templateUrl: './verify-thaid.component.html',
  styleUrl: './verify-thaid.component.scss'
})
export class VerifyThaidComponent {

  protected readonly environment = environment;
  private oidcSecurityService = inject(OidcSecurityService);
  private router: Router = inject(Router);
  user: SsoUser
  setPidUrl:string

  constructor() {
    this.oidcSecurityService.userData$.subscribe(e => {
      this.user = e.userData as SsoUser;
      console.log(this.user)
      if(this.user?.pid){
        this.router.navigate(['/welcome'])
      }else{
        this.setPidUrl = `${environment.serverUrl}/api/public/thaid/initial-set-pid?sub=${this.user?.sub}`
        console.log(this.setPidUrl)
      }
    })

  }
}
