import {AfterViewInit, Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[customNavCollapse]',
  standalone: true
})
export class NavCollapseDirective implements OnInit, AfterViewInit {

  constructor(public elementRef: ElementRef, public renderer: Renderer2) {
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    setTimeout(() => {
      let list = this.elementRef.nativeElement.querySelectorAll('[data-bs-toggle="collapse"]');
      // @ts-ignore
      list.forEach((toggler, key) => {
        let dataBsTarget = toggler.getAttribute('data-bs-target');
        let collapsedElement = document.querySelector(`${dataBsTarget}`);
        let childrenActiveList = document.querySelectorAll(`${dataBsTarget} a.active`);
        if (childrenActiveList.length) {
          // toggler.classList.add('active');
          toggler.classList.add('collapsed');
          collapsedElement.classList.add('show');
          toggler.setAttribute('aria-expanded', 'true');
        } else {
          // toggler.classList.remove('active');
          toggler.classList.remove('collapsed');
          collapsedElement.classList.remove('show');
          toggler.setAttribute('aria-expanded', 'false');
        }
      });
    });
  }
}
