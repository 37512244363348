import {Component, inject, OnInit,} from '@angular/core';
import {Router, RouterLink, RouterLinkActive, RouterOutlet} from "@angular/router";
import {LoadingComponent} from '../../shared/commons/loader/loading.component';
import {AppHeaderComponent} from '../../shared/commons/app-header/app-header.component';
import {NgTemplateOutlet} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FeedbackModalComponent} from '../modal/feedback-modal/feedback-modal.component';
import {NgbdToastGlobalModule} from '../../shared/commons/toast/toast-global.module';
import {NavCollapseDirective} from '../../shared/commons/nav-collapse.directive';
import {OidcSecurityService, UserDataResult} from 'angular-auth-oidc-client';
import {environment} from '../../../environments/environment';
import {HasAnyRoleDirective} from '../../auth/has-any-role.directive';
import {ManufacturerRest} from '../../service/ManufacturerRest';
import {map} from 'rxjs';
import {filter} from 'rxjs/operators';
import {SsoUser} from '../../app.constants';

@Component({
  selector: 'app-layout',
  standalone: true,
  templateUrl: './layout.component.html',
  imports: [
    RouterOutlet,
    LoadingComponent,
    AppHeaderComponent,
    RouterLinkActive,
    RouterLink,
    NgTemplateOutlet,
    NgbdToastGlobalModule,
    NavCollapseDirective,
    HasAnyRoleDirective
  ],
  styleUrl: './layout.component.scss'
})
export class LayoutComponent implements OnInit {
  private router = inject(Router);
  private modalService = inject(NgbModal);
  private oidcSecurityService = inject(OidcSecurityService)
  manufacturerRest = inject(ManufacturerRest)
  protected readonly environment = environment;
  protected userData: SsoUser;

  ngOnInit(): void {
    this.oidcSecurityService.userData$
      .pipe(map(e => e.userData))
      .pipe(filter(e => !!e))
      .subscribe(e => {
        this.userData = e
      })
  }

  next() {
    this.router.navigate(['/register/finish']);
  }

  feedBackModal() {
    const modalRef = this.modalService.open(FeedbackModalComponent, {
      size: "md"
    });
  }

  logout() {
    console.log('logout')
    this.oidcSecurityService
      .logoff()
      .subscribe((result) => console.log(result));
  }
}
