import {Component, inject, OnInit, signal} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {Page, UserManageGroupDto, UserManageGroupSearchParams} from '../../../generated-model/model';
import {DecimalPipe, NgForOf, NgIf} from '@angular/common';
import {NotFoundComponent} from '../../shared/not-found/not-found.component';
import {ManagerUserModalComponent} from './manager-user-modal/manager-user-modal.component';
import {UserRest} from '../../service/UserRest';
import {IgnoreNullHttpParams} from '../../shared/Ignore-null-http-params';
import {from} from 'rxjs';
import {filter} from 'rxjs/operators';
import {HasAnyRoleDirective} from '../../auth/has-any-role.directive';

@Component({
  selector: 'app-manager-user',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgbPagination,
    NgForOf,
    NgIf,
    NotFoundComponent,
    DecimalPipe,
    FormsModule,
    HasAnyRoleDirective
  ],
  templateUrl: './manager-user.component.html',
  styleUrl: './manager-user.component.scss'
})
export class ManagerUserComponent implements OnInit {
  pageData = signal<Page<UserManageGroupDto>>({size: 10})
  searchParams = signal<UserManageGroupSearchParams>({enable: true})
  userService = inject(UserRest)
  private modalService = inject(NgbModal);

  ngOnInit() {
    this.search()
  }

  search(page: number = 1) {
    const pageRequest = {page: page - 1, size: this.pageData().size, sort: 'createdDate,desc'}
    const params = IgnoreNullHttpParams.fromObject({...this.searchParams()})
      .set("page", pageRequest.page)
      .set("size", pageRequest.size)
      .set("sort", pageRequest.sort)
    this.userService.searchGroup(params).subscribe({
      next: value => {
        this.pageData.set(value)
      },
      error(){
        console.log("error")
      }
    })
  }

  clear(){
    this.searchParams.set({enable: true})
    this.search()
  }

  openModel(prefix: string) {
    if (prefix == 'เพิ่ม') {
      const modalRef = this.modalService.open(ManagerUserModalComponent, {
        size: 'xl'
      });
      modalRef.componentInstance.prefix = 'ระบุ e-Mail เพื่อเพิ่มผู้ใช้งานในกลุ่ม/บริษัท'
      from(modalRef.result).pipe(filter(e => !!e))
        .subscribe(e => {
          this.search(1)
        });
    }
  }
}
