import {Component, inject} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-register-policy',
  standalone: true,
  imports: [],
  templateUrl: './register-policy.component.html',
  styleUrl: './register-policy.component.scss'
})
export class RegisterPolicyComponent {
  private router = inject(Router);

  back() {
    this.router.navigate(['/']);
  }

  next() {
    this.router.navigate(['/register/start-thaid']);
  }
}
