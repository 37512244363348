import {Component, inject, OnInit, signal, ViewChild} from '@angular/core';
import {FormsModule, NgForm} from '@angular/forms';
import {SharedCommonModule} from '../../shared/shared-common.module';
import {FileUploader, FileUploadModule} from 'ng2-file-upload';
import {NgIf} from '@angular/common';
import {ManufacturerRest} from '../../service/ManufacturerRest';
import {
  ActiveStatus,
  BusinessType,
  BusinessTypeArray,
  CertificateDto,
  ManufacturerDto,
  ManufacturerType,
  ManufacturerTypeArray,
  PlaceOfDistribution,
  Sources
} from '../../../generated-model/model';
import {ConfirmDialogService} from '../../shared/commons/dialog/confirm-dialog/confirm-dialog.service';
import {ToastService} from '../../shared/commons/toast/toast-service';
import {filter} from 'rxjs/operators';
import {FILE_1MB, SsoUser, updateFormAndValidity} from '../../app.constants';
import {DigitOnlyModule} from '@uiowa/digit-only';
import {
  ProvinceAutocompleteComponent
} from '../../shared/components/province-autocomplete/province-autocomplete.component';
import {
  DistrictAutocompleteComponent
} from '../../shared/components/district-autocomplete/district-autocomplete.component';
import {
  SubDistrictAutocompleteComponent
} from '../../shared/components/subdistrict-autocomplete/sub-district-autocomplete.component';
import {PlaceOfDistributionRest} from '../../service/PlaceOfDistributionRest';
import {CustomErrorComponent} from '../../shared/custom-error.component';
import {Router} from '@angular/router';
import {NotFoundComponent} from '../../shared/not-found/not-found.component';
import {CertificateRest} from '../../service/CertificateRest';
import {IgnoreNullHttpParams} from '../../shared/Ignore-null-http-params';
import {AwsRest} from '../../service/AwsRest';
import {FileImageComponent} from '../../shared/components/file-image/file-image.component';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {FormDisabledDirective} from '../../shared/commons/form-disabled.directive';
import {HasAnyRoleDirective} from '../../auth/has-any-role.directive';

@Component({
  selector: 'app-manufacturer',
  standalone: true,
  imports: [
    FormsModule,
    SharedCommonModule,
    FileUploadModule,
    NgIf,
    DigitOnlyModule,
    ProvinceAutocompleteComponent,
    DistrictAutocompleteComponent,
    SubDistrictAutocompleteComponent,
    CustomErrorComponent,
    NotFoundComponent,
    FileImageComponent,
    FormDisabledDirective,
    HasAnyRoleDirective
  ],
  templateUrl: './manufacturer.component.html',
  styleUrl: './manufacturer.component.scss'
})
export class ManufacturerComponent implements OnInit {
  protected businessTypeArray = BusinessTypeArray
  protected readonly businessType = BusinessType;
  protected readonly manufacturerTypeArray = ManufacturerTypeArray
  protected readonly manufacturerType = ManufacturerType
  protected readonly activeStatus = ActiveStatus;
  protected readonly sources = Sources;
  protected readonly FILE_1MB = FILE_1MB;

  manufacturerRest = inject(ManufacturerRest)
  confirmDialogService = inject(ConfirmDialogService)
  toastService = inject(ToastService)
  placeOfDistributionRest = inject(PlaceOfDistributionRest)
  router = inject(Router)
  certificateRest = inject(CertificateRest)
  private oidcSecurityService = inject(OidcSecurityService)

  item = signal({address: {}, contractUser: {}} as ManufacturerDto)
  selectedPlaceOfDistribution = signal<number[]>([])
  isDisable = signal<boolean>(false)
  notEditable = signal<boolean>(false)
  haveCode: boolean = false;
  isShopEtc: boolean = false;
  uploader: FileUploader;
  placeOfDistribution = signal([] as PlaceOfDistribution[])
  certificates = signal([] as CertificateDto[])
  @ViewChild('form', {static: true}) form: NgForm

  loading = false;
  deleteManuFacId: number = null;
  manuFacImage: File = null;
  awsService = inject(AwsRest)
  user: SsoUser;

  ngOnInit() {
    this.placeOfDistributionRest.getAll().subscribe(
      e => {
        this.placeOfDistribution.set(e)
        console.log(this.placeOfDistribution())
        this.searchManufacturer()
      }
    )
  }

  searchManufacturer() {
    this.oidcSecurityService.userData$.subscribe(u => {
      this.user = u.userData as SsoUser
      this.manufacturerRest.findBySsoUserId()
        .subscribe(e => {
          if (e) {
            if (e.id) {
              console.log(e)
              this.haveCode = true
              this.isDisable.set(true)
              this.notEditable.set(true)
              this.selectedPlaceOfDistribution.set(e.placeOfDistributions)
              this.searchCertificate()
            }
            this.item.set(e)
            if (!e.contractUser) {
              this.item().contractUser = {}
            }
          }
        })
    })
  }

  goToGoogleMap() {
    window.open('https://www.google.com/maps', "_blank");
  }

  toggleShopEtc() {
    this.isShopEtc = !this.isShopEtc;
  }

  async upload() {
    try {
      if (this.manuFacImage) {
        const manuFacImageResult = await this.awsService.upload(this.manuFacImage).toPromise();
        this.item.update(val => ({
          ...val,
          manufacturerImage: manuFacImageResult
        }));
      }

      if (this.deleteManuFacId) {
        await this.awsService.delete(this.deleteManuFacId).toPromise();
      }
      return true;
    } catch (error) {
      console.error("Error uploading files:", error);
      this.toastService.showDangerWithMessage('เกิดข้อผิดพลาดในการอัพโหลดไฟล์');
      return false;
    }
  }

  save() {
    let valid = updateFormAndValidity(this.form)
    if (valid) {
      this.item().placeOfDistributions = this.selectedPlaceOfDistribution()
      try {
        if (this.upload()) {
          this.confirmDialogService.open({content: ' ยืนยันการบันทีกข้อมูล'}).pipe(filter(e => !!e))
            .subscribe({
              next: e => {
                const isNewManufact = !this.item().id
                this.manufacturerRest.save(this.item()).subscribe({
                  next: value => {
                    this.toastService.showSuccess('บันทึกข้อมูลสำเร็จ')
                    if(isNewManufact){
                      this.oidcSecurityService.forceRefreshSession().subscribe(e=>{
                        console.log("refresh session success",e?.userData)
                      })
                    }
                    this.searchManufacturer()
                  }
                })
              }
            })
        }
      } catch (e) {
        console.error(e)
      }
    } else {
      this.toastService.showWarning('กรุณากรอกข้อมูลให้ครบถ้วน')
    }
  }

  onManufacturerTypeChange() {
    let businessTypeAll = BusinessTypeArray
    this.businessTypeArray = [...businessTypeAll]
    if (this.item().manufacturerType == 'JURISTIC_PERSON') {
      this.businessTypeArray = [...businessTypeAll.filter(e => e != 'FARMER')]
      this.item().businessType = undefined
    } else if (this.item().manufacturerType == 'NATURAL_PERSON') {
      this.businessTypeArray = [...businessTypeAll.filter(e => e != 'COMPANY')]
      this.item().businessType = undefined
    } else {
      this.item().businessType = undefined
    }
  }


  onPlaceChange($event: Event) {
    let target = $event.target as HTMLInputElement;
    if (target.checked) {
      this.selectedPlaceOfDistribution().push(+target.value)
    } else {
      this.selectedPlaceOfDistribution.set(this.selectedPlaceOfDistribution().filter(e => e != (+target.value)))
    }
    console.log(this.selectedPlaceOfDistribution())
  }

  cancel() {
    this.searchManufacturer()
  }

  findCertificateFormThaiTraces() {
    this.certificateRest.findAndSaveCertFromThaiTraces().subscribe({
      next: result => {
        this.toastService.showSuccess('เชื่อมโยงใบรับรองจาก Thai traces สำเร็จ')
        this.searchCertificate()
      }
    })
  }

  onManufacturerImageChanged(event: { newFiles: File[], deletedFileIds: number[] }) {
    this.manuFacImage = event.newFiles.length > 0 ? event.newFiles[0] : null;

    if (event.deletedFileIds.length > 0 && this.item().manufacturerImage) {
      this.deleteManuFacId = this.item().manufacturerImage.id;
      this.item.update(val => ({
        ...val,
        manufacturerImage: null
      }));
    }
  }

  searchCertificate(page: number = 1) {
    let param = IgnoreNullHttpParams.fromObject({certificateGroup: "PRODUCTION"})
      .set("page", page - 1)
      .set("size", 100)
      .set("sort", "id,desc")
    this.certificateRest.search(param).subscribe(
      data => {
        this.certificates.set(data.content)
      }
    )
  }

  edit() {
    this.isDisable.set(false)
  }

  checked(id: number): boolean {
    return this.selectedPlaceOfDistribution().some(e => e == id)
  }

  fillContractUser() {
    this.item().contractUser.fullName = this.user.given_name + ' ' + this.user.family_name
    this.item().contractUser.userName = this.user.preferred_username
    this.item().contractUser.email = this.user.email
    this.item().contractUser.telephoneNo = this.user.phone_number
    console.log(this.item().contractUser)
  }
}

