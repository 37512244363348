import {Component, inject, OnDestroy, OnInit, signal} from '@angular/core';
import {ManufacturerRest} from '../../service/ManufacturerRest';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Router} from '@angular/router';
import {interval, map, Subscription, take} from 'rxjs';
import {SsoUser} from '../../app.constants';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-welcome',
  standalone: true,
  imports: [],
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss'
})
export class WelcomeComponent implements OnInit, OnDestroy {
  private oidcSecurityService = inject(OidcSecurityService)
  public router: Router = inject(Router);
  countdown: number = 6;
  countdownSubscription: Subscription | undefined;
  isCountdownActive = signal(false)
  manufacturerRest = inject(ManufacturerRest)
  userData: any;

  ngOnInit(): void {
    this.oidcSecurityService.userData$
      .pipe(map(e => e.userData))
      .pipe(filter(e => !!e))
      .subscribe(e => {
        console.log(e)
        this.userData = e as SsoUser
        if (this.userData?.resource_access?.['qrtraces']?.roles.includes('user')) {
          this.manufacturerRest.findBySsoUserId().subscribe(e => {
            console.log("login as ", e?.businessType)
            if (!e) {
              this.startCountdown();
            }
          })
        }
      })
  }

  startCountdown() {
    this.isCountdownActive.set(true)
    this.countdownSubscription = interval(1000)
      .pipe(take(this.countdown))
      .subscribe(() => {
        this.countdown--;
        if (this.countdown === 0) {
          this.router.navigate(['manufacturer']);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }

}
