import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RequestUserDto} from '../../generated-model/model';
import {environment} from '../../environments/environment';

@Injectable({providedIn: "root"})
export class UserRegisterResource {
  http = inject(HttpClient);

  register(item: RequestUserDto) {
    return this.http.post(`${environment.serverUrl}/public/user-register`, item)
  }
}
