<div class="container">
  <div class="row">
    <div class="col-xxl-4 d-none d-xxl-block my-auto text-center">
      <img src="assets/images/login.png" class="img">
    </div>
    <div class="col-xxl-8 col-md-12">
      <div class="title">
        Welcome : ระบบตามสอบสินค้าเกษตร <br> บนระบบคลาวด์ (QR Trace on Cloud)
      </div>
      <div class="card custom-box my-3 border-0 card-login">
        <div class="card-body">
          <div class="row">
            <div class="col-xxl-4 col-md-12">
              <img src="assets/images/logo_acfs.png" class="logo">
            </div>
            <div class="col-xxl-8 col-md-12">
              <div class="row mt-3">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="bi bi-person-fill fs-5"></i>
                    </span>
                  </div>
                  <input type="text" class="form-control">
                </div>
              </div>
              <div class="row mt-3">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                     <i class="bi bi-lock-fill fs-5"></i>
                    </span>
                  </div>
                  <input [type]="visible ? 'text' : 'password'" class="form-control input-pass">
                  <div class="input-group-prepend">
                    <span class="input-group-text-pass" (click)="viewPassword()">
                     <i [ngClass]="visible ? 'bi bi-eye fs-5' : 'bi bi-eye-slash-fill fs-5' "></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="text-center">
                  <button type="button"
                          class="btn btn-custom btn-custom-theme me-2 w-100"
                          (click)="login()">
                    <i class="bi bi-key fs-5 me-1"></i>
                    Login
                  </button>
                </div>
              </div>
              <div class="row mt-3">
                <div class="text-center">
                  or sign up with
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-6 text-center">
                  <button class="btn btn-custom btn-light me-2 w-100">
                    <img src="assets/images/thaid.png" class="img-icon">
                    Login with ThaID
                  </button>
                </div>
                <div class="col-6 text-center">
                  <button class="btn btn-custom btn-light me-2 w-100">
                    <img src="assets/images/line.png" class="img-icon">
                    Login with Line
                  </button>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-6 text-center">
                  <button class="btn btn-custom btn-light me-2 w-100">
                    <img src="assets/images/facebook.png" class="img-icon">
                    Login with Facebook
                  </button>
                </div>
                <div class="col-6 text-center">
                  <button class="btn btn-custom btn-light me-2 w-100">
                    <img src="assets/images/google.png" class="img-icon">
                    Login with Google
                  </button>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-6 text-center">
                  <button class="btn btn-custom btn-light me-2 w-100">
                    <img src="assets/images/microsoft.png" class="img-icon">
                    Login with Microsoft
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-title">
        ท่านสามารถลงทะเบียนสมัครสมาชิกเพื่อเข้าสู่ระบบตามสอบสินค้าเกษตร<br>บนระบบคลาวด์ (QR Trace on Cloud)
        โดยต้องทำการพิสูจน์ตัวตนผ่าน ThaiD <br>และ ทางอีเมลที่ระบบจัดส่งให้
        โดยคลิกที่ <a routerLink="/register" style="font-size: 1.5rem">ลงทะเบียน</a>
      </div>
    </div>
  </div>
</div>


